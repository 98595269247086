import laptopImage from '../img/laptop.png'

function OnboardingStep({ number=1, isActive=false }) {
	return <li className="onboarding-step">
		{
			isActive
			? <>
				<svg xmlns="http://www.w3.org/2000/svg" width="106" height="106" viewBox="0 0 106 106" fill="none">
				  <g filter="url(#filter0_d_119_582)">
				    <circle cx="53" cy="45" r="29" fill="url(#paint0_linear_119_582)" fill-opacity="0.5" shape-rendering="crispEdges"/>
				  </g>
				  <defs>
				    <filter id="filter0_d_119_582" x="0" y="0" width="106" height="106" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
				      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
				      <feOffset dy="8"/>
				      <feGaussianBlur stdDeviation="12"/>
				      <feComposite in2="hardAlpha" operator="out"/>
				      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
				      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_119_582"/>
				      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_119_582" result="shape"/>
				    </filter>
				    <linearGradient id="paint0_linear_119_582" x1="82" y1="63.6889" x2="21.1764" y2="57.8972" gradientUnits="userSpaceOnUse">
				      <stop stop-color="#0C5FDC"/>
				      <stop offset="1" stop-color="#73BAFB"/>
				    </linearGradient>
				  </defs>
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" width="98" height="98" viewBox="0 0 98 98" fill="none">
					<g filter="url(#filter0_d_119_583)">
					  <circle cx="49" cy="41" r="25" fill="url(#paint0_linear_119_583)"/>
					</g>
					<defs>
					  <filter id="filter0_d_119_583" x="0" y="0" width="98" height="98" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
					    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					    <feOffset dy="8"/>
					    <feGaussianBlur stdDeviation="12"/>
					    <feComposite in2="hardAlpha" operator="out"/>
					    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
					    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_119_583"/>
					    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_119_583" result="shape"/>
					  </filter>
					  <linearGradient id="paint0_linear_119_583" x1="74" y1="57.1111" x2="21.5659" y2="52.1183" gradientUnits="userSpaceOnUse">
					    <stop stop-color="#0C5FDC"/>
					    <stop offset="1" stop-color="#73BAFB"/>
					  </linearGradient>
					</defs>
				</svg>
			</>
			: <>
				<svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
		  		<g filter="url(#filter0_d_119_586)">
		    		<circle cx="52" cy="44" r="28" fill="url(#paint0_linear_119_586)" fill-opacity="0.5" shape-rendering="crispEdges"/>
		  		</g>
		  		<defs>
		    		<filter id="filter0_d_119_586" x="0" y="0" width="104" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
					    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
					    <feOffset dy="8"/>
					    <feGaussianBlur stdDeviation="12"/>
					    <feComposite in2="hardAlpha" operator="out"/>
					    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
					    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_119_586"/>
					    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_119_586" result="shape"/>
		  			</filter>
		    		<linearGradient id="paint0_linear_119_586" x1="80" y1="62.0444" x2="21.2738" y2="56.4524" gradientUnits="userSpaceOnUse">
		      		<stop stop-color="#B1B5C3"/>
		      		<stop offset="1" stop-color="#73BAFB"/>
		    		</linearGradient>
		  		</defs>
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 94 94" fill="none">
				  <g filter="url(#filter0_d_119_587)">
				    <circle cx="47" cy="39" r="23" fill="#F0F0F0"/>
				  </g>
				  <defs>
				    <filter id="filter0_d_119_587" x="0" y="0" width="94" height="94" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
				      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
				      <feOffset dy="8"/>
				      <feGaussianBlur stdDeviation="12"/>
				      <feComposite in2="hardAlpha" operator="out"/>
				      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
				      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_119_587"/>
				      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_119_587" result="shape"/>
				    </filter>
				  </defs>
				</svg>
			</>
		}

		<span className={ isActive ? 'is-active' : '' }>{number}.</span>
	</li>
}

export default function Onboarding() {
  return <div className="tall-page">
      <div className="site-content">

      	<ul className="onboarding-steps">
	    		<OnboardingStep 
	    			number="1" 
	    			isActive={true} 
    			/>
	    		<OnboardingStep 
	    			number="2" 
    			/>
	    		<OnboardingStep 
	    			number="3" 
    			/>
	    		<OnboardingStep 
	    			number="4" 
    			/>
	    	</ul>

	    	<div className="onboarding-screen">
	      	<div className="onboarding-info">
			    	<h2 className="size-s">Lorem Ipsum Dolor</h2>
			    	<p>
			    		Libero posuere eget semper enim nunc sed tortor maecenas velit. Nibh leo imperdiet justo ullamcorper vestibulum scelerisque. Venenatis egestas quam volutpat eget aliquam. Consequat vitae cursus a pellentesque. Metus pulvinar vitae sit in at. Luctus purus consectetur sollicitudin at. Mi rutrum quisque nunc eget etiam et at sollicitudin. 
		    		</p>
		    		<div className="onboarding-info-buttons">
				    	<button className="button is-white">Skip</button>
				    	<button className="button">Next</button>
			    	</div>
		    	</div>
	    		<div className="onboarding-media">
	    			<div className="onboarding-media-bkg"></div>
        		<img src={laptopImage} alt="Preview on laptop" />
	      	</div>
      	</div>
      </div>
  </div>
}
