import { useState, useEffect } from 'react'
import axios from 'axios'
import useUserPermissions from '../hooks/useUserPermissions'
import Properties from '../components/Properties'

export default function Dashboard() {
	const [user, setUser] = useState(null)
	const userId = localStorage.getItem('1on1Home_userId')
	const { isAdmin } = useUserPermissions()

	useEffect(() => {
		axios.get(`/users/${userId}`).then(({data}) => setUser(data.data))
	}, [userId])

	if (isAdmin) {
		return <>
			<h2 className="admin-page-title">Welcome, Andy!</h2>
			<p>Please search any client (by name, email or phone) using the sidebar search field.</p>
		</>
	}

  return <>
  	<h2 className="admin-page-title">Dashboard</h2>
  	{/*
  	<div className="summary-boxes">
  		<div>
  			<div>Active Properties</div>
  			<div>0</div>
  		</div>
  		<div>
  			<div>Total Properties</div>
  			<div>0</div>
  		</div>
  		<div>
  			<div>Total Views</div>
  			<div>0</div>
  		</div>
  		<div>
  			<div>Total Offers</div>
  			<div>0</div>
  		</div>
  	</div>
  	*/}
  	<div className="widgets">
  		{/*
  		<div>
  			<div className="admin-section-title">Total Views / per month</div>
  			<table className="fake-chart">
  				<tbody>
	  				<tr>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  				</tr>
	  				<tr>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  				</tr>
	  				<tr>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  				</tr>
	  				<tr>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  				</tr>
	  				<tr>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  				</tr>
	  				<tr>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  					<td></td>
	  				</tr>
  				</tbody>
  			</table>
			</div>
			*/}
			<div>
  			<div className="admin-section-title">Active Property</div>
				{
					(user && user.properties ? user.properties : []).length
					? <Properties 
						realData={true}
						hidePrice={true}
	  				hideHeadBar={true}
	  				showContactForm={true}
	  				className="is-animated"
	  				properties={user.properties}
					/>
					: <div>
						You have no property listed at the moment...
					</div>
				}
			</div>
  	</div>
  	{/*
  	<div className="admin-section-title">Financial Overview</div>
		<div className="scrollable-holder">
			<table className="scrollable">
				<thead>
					<tr>
		  			<th>
		  				Property ID
		  				<ChevronIcon />
	  				</th>
	  				<th>
		  				Status
		  				<ChevronIcon />
	  				</th>
		  			<th>
		  				Set Date
		  				<ChevronIcon />
	  				</th>
		  			<th>
		  				End Date
		  				<ChevronIcon />
						</th>
		  			<th>
		  				Initial Listing Price
		  				<ChevronIcon />
	  				</th>
		  			<th>
		  				Current Price
		  				<ChevronIcon />
	  				</th>
		  			<th>
		  				Net proceeds after the sale
		  				<ChevronIcon />
	  				</th>
		  			<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{
						[].map(index => <tr key={index}>
				      <td>#125992</td>
				      <td className="status">Active</td>
				      <td>08/05/2023</td>
				      <td>-</td>
				      <td>$1.258.00</td>
				      <td className="price">
				      	<span>$1.199.00</span>
				      	<small>-0.56%</small>
				      	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0_119_1369)">
										<path d="M12.7617 10.1396L7.62036 4.99824L4.9144 7.7042L0.855469 3.64526" stroke="#FF0000" strokeWidth="1.08238" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M9.51367 10.1395H12.7608V6.89233" stroke="#FF0000" strokeWidth="1.08238" strokeLinecap="round" strokeLinejoin="round"/>
									</g>
									<defs>
										<clipPath id="clip0_119_1369">
											<rect width="12.9886" height="12.9886" fill="white" transform="translate(0.314453 0.398193)"/>
										</clipPath>
									</defs>
								</svg>
				      </td>
				      <td>
				      	{ index === 1 ? '$1.258.00' : '-' }
			      	</td>
				      <td className="action-buttons">
				      	<EyeIcon />
				      	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
								  <path d="M9 15H15.75" stroke="#B1B5C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
								  <path d="M12.375 2.62505C12.6734 2.32668 13.078 2.15906 13.5 2.15906C13.7089 2.15906 13.9158 2.20021 14.1088 2.28016C14.3019 2.36012 14.4773 2.47731 14.625 2.62505C14.7727 2.77278 14.8899 2.94817 14.9699 3.1412C15.0498 3.33423 15.091 3.54112 15.091 3.75005C15.091 3.95898 15.0498 4.16587 14.9699 4.35889C14.8899 4.55192 14.7727 4.72731 14.625 4.87505L5.25 14.25L2.25 15L3 12L12.375 2.62505Z" stroke="#B1B5C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
				      	<TrashIcon />
				      </td>
			      </tr>)
					}
					<tr>
						<td colSpan="8">
							You have no financial stats at the moment...
						</td>
					</tr>
		    </tbody>
	    </table>
    </div>
    */}
  </>
}
