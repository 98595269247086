import { useEffect, useRef } from 'react'
import TextAndImageSection from '../components/TextAndImageSection'
import SavingsSimulator from '../components/SavingsSimulator'
import BlogArticles from '../components/BlogArticles'
import CustomLink from '../components/CustomLink'
import Properties from '../components/Properties'
import TrendsBox from '../components/TrendsBox'
import SearchBox from '../components/SearchBox'
import Reviews from '../components/Reviews'
import CtaBox from '../components/CtaBox'
import { initAnimationObserver } from '../helpers'

import googleReviews from '../img/google-reviews.png'
import iconHow1 from '../img/icon-how1.png'
import iconHow2 from '../img/icon-how2.png'
import iconHow3 from '../img/icon-how3.png'
import iconHow4 from '../img/icon-how4.png'

export default function Home() {
  const benefitsElement = useRef()

  useEffect(() => initAnimationObserver(benefitsElement), [benefitsElement])

  return <>
    <div className="home-hero">
      <div className="home-hero-bkg1">
        <div className="home-hero-inner">
          <h2>Premium Real Estate Brokerage Services at a Fraction of the Cost</h2>
          <p>Maximize the return on your investment by leveraging our proven expertise to sell your home for the highest possible price, while minimizing fees, ensuring you keep more of your hard-earned money in your pocket.</p>
          <div className="search-options">
            <span className="sell active">Sell</span>
            <CustomLink to="/contact" className="questions">Questions</CustomLink>
          </div>
          <div className="search-form">
            <div className="note">
              Discover Your Potential Savings with 1on1 Home: Simply Enter Your Address to Find Out How Much You Can Save.
            </div>
            <SearchBox />
          </div>
          <div className="contact-expert">
            <div className="details">
              <div className="details-box">
                Get Your Free Home Analysis & Start Today
              </div>
              <div className="details-box">
                30 Day Listing Agreements
              </div>
              <div className="details-box">
                Receive A Guaranteed Offer On Your Property TODAY!
              </div>
            </div>
            <CustomLink to="/contact" className="button bordered">Speak to an Expert</CustomLink>
          </div>
          <div className="google-reviews">
            <img src={googleReviews} alt="Google Reviews - 5 Stars" />
          </div>
        </div>
        <video loop autoPlay muted playsInline>
          <source src="https://1on1home.com/andy_vid_720p.mp4" type="video/mp4" />
        </video>
        <div className="video-mask"></div>
      </div>
    </div>

    <Reviews />
    <SavingsSimulator />

    <div className="benefits-box" ref={benefitsElement}>
      <div className="site-content">
        <h2>How 1on1 Home Helps you save thousands</h2>
        <div className="benefits-box-list">
          <div className="items-row">
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow1} alt="In-Depth Market Insight" />
              </figure>
              <h3>
                In-Depth 
                <br/>
                Market Insight
              </h3>
              <div>We are dedicated to researching the local real estate market, keeping you informed about property values, market trends, and potential cost-saving opportunities.</div>
            </div>
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow2} alt="Masterful Negotiation Skills" />
              </figure>
              <h3>
                Masterful 
                <br/>
                Negotiation Skills
              </h3>
              <div>As your real estate agents, we leverage our negotiation expertise to secure the best possible price and favorable terms, ensuring that you get the most value out of your investment.</div>
            </div>
          </div>
          <div className="items-row">
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow3} alt="Avoid Unnecessary Fees" />
              </figure>
              <h3>
                Avoid Unnecessary
                <br/>
                Fees
              </h3>
              <div>At 1on1 Home, we excel at optimizing your financial outcome by expertly reducing your closing costs, ensuring substantial savings in your real estate transactions.</div>
            </div>
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow4} alt="We Handle EVERYTHING" />
              </figure>
              <h3>
                We Handle 
                <br/>
                EVERYTHING
              </h3>
              <div>Experience our comprehensive listings with a dedicated 1on1 Home Agent, providing round-the-clock support and unparalleled exposure on the MLS, Zillow, Redfin, and Realtor.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/*
    <div className="site-content">
      <div className="reviews">
        <div className="section-title">Testimonials</div>
        <div className="row">
          <div className="item">
            <div className="review-title">It was a great decision to engage with 1on1Home!</div>
            <div className="stars">
              <span className="star"></span>
              <span className="star"></span>
              <span className="star"></span>
              <span className="star"></span>
            </div>
            <div className="review-body">
              <p>I had a great experience with 1on1Home team and saved lots of money.</p>
              <p>I had my free consultation and then the team proceeded right away with the forms.</p>
              <p>I've sold my property and I am more than happy with my decision on working with 1on1 team. Thanks!</p>
            </div>
            <div className="review-details">
              <span className="author-name">J. Dean</span>
              <span className="date">12/01/24</span>
            </div>
          </div>
          <div className="item">
            <div className="review-title">It was a great decision to engage with 1on1Home!</div>
            <div className="stars">
              <span className="star"></span>
              <span className="star"></span>
              <span className="star"></span>
              <span className="star"></span>
            </div>
            <div className="review-body">
              <p>I had a great experience with 1on1Home team and saved lots of money.</p>
              <p>I had my free consultation and then the team proceeded right away with the forms.</p>
              <p>I've sold my property and I am more than happy with my decision on working with 1on1 team. Thanks!</p>
            </div>
            <div className="review-details">
              <span className="author-name">J. Dean</span>
              <span className="date">12/01/24</span>
            </div>
          </div>
          <div className="item">
            <div className="review-title">It was a great decision to engage with 1on1Home!</div>
            <div className="stars">
              <span className="star"></span>
              <span className="star"></span>
              <span className="star"></span>
              <span className="star"></span>
            </div>
            <div className="review-body">
              <p>I had a great experience with 1on1Home team and saved lots of money.</p>
              <p>I had my free consultation and then the team proceeded right away with the forms.</p>
              <p>I've sold my property and I am more than happy with my decision on working with 1on1 team. Thanks!</p>
            </div>
            <div className="review-details">
              <span className="author-name">J. Dean</span>
              <span className="date">12/01/24</span>
            </div>
          </div>
        </div>
        <div className="bullets">
          <span className="dot" onclick="currentSlide(1)"></span> 
          <span className="dot" onclick="currentSlide(2)"></span> 
          <span className="dot" onclick="currentSlide(3)"></span> 
        </div>
      </div>
    </div>
    */}

    <div className="site-content">
      <TextAndImageSection 
        showMoreButton={true}
        showVideo={true}
      />
    </div>

    <div className="site-content">
      <TrendsBox />
      <CtaBox />
      <BlogArticles />
    </div>

    <div className="site-content">
      <Properties 
        max={3}
        retsData={true}
        title="Top Properties To Watch In Palm Beach County"
      />
    </div>
  </>
}
