import React, { Component } from 'react'
import { formatPhoneNumber } from '../helpers'

export default class PhoneField extends Component {
	onChange(event) {
		const { onChange } = this.props
		const { currentTarget } = event
		const newValue = currentTarget.value.trim()
		const chars = '1234567890-'

		if (newValue.length > 0 && chars.indexOf(newValue[newValue.length-1]) === -1) {
			return false
		}

		let value = newValue.replace(/ /g, '').replace(/-/g, '')
		value = value.toString().length > 10 ? value.toString().substring(0, 10) : value.toString()
		
		onChange(value)
	}

	render() {
		const { value, placeholder, name } = this.props
		const newValue = formatPhoneNumber(value.toString().length > 10 ? value.toString().substring(0, 10) : value.toString())

		return <input 
			placeholder={ placeholder } 
			type="tel" 
			name={ name } 
			value={ newValue } 
			autoComplete="zxc"
			onChange={ event => this.onChange(event) }
		/>
  }
}
