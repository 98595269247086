import axios from 'axios'
import { useState } from 'react'
import PhoneField from './PhoneField'

export default function ContactForm() {
	const [name, setName] = useState('')
	const [phone, setPhone] = useState('')
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [formFeedback, setFormFeedback] = useState('')

  return <form className={`status-${formFeedback}`} onSubmit={event => {
		event.preventDefault()

		setFormFeedback('')

		if (!name.trim().length || !phone.trim().length || !email.trim().length) {
			setFormFeedback('error')
		} else {
			setFormFeedback('success')

			axios.post('/inquiries', {
        name,
        phone,
        email,
        message
      })
		}
	}}>
  	<div className="formfield">
			<input 
				type="text" 
				value={name}
				placeholder="Name" 
				onChange={event => setName(event.target.value)} 
			/>
		</div>
    <div className="formfield">
			<PhoneField 
				placeholder="Phone" 
				name="phone" 
				value={phone} 
				onChange={number => setPhone(number)} 
			/>
		</div>
    <div className="formfield">
			<input 
				type="email" 
				value={email}
				placeholder="Email" 
				onChange={event => setEmail(event.target.value)} 
			/>
		</div>
    <div className="formfield">
			<textarea 
				value={message}
				placeholder="Message"
				onChange={event => setMessage(event.target.value)} 
			></textarea>
		</div>

		{
			formFeedback
			? <>
				{
					formFeedback === 'error'
					? <div className="form-error">All the fields are required</div>
					: <div className="form-success">Thanks for contacting us!</div>
				}
			</>
			: null
		}

    <button className="button full height-l">Contact Now</button>
  </form>
}
