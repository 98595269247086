import CustomLink from './CustomLink'
import PropertyPrice from './PropertyPrice'
import PropertyLocation from './PropertyLocation'

import temp1 from '../img/temp1.jpg'

export default function PropertyRets({ data }) {
  return <article>
    <figure>
      <CustomLink to={`/property/${data.ListingId}`}>
      	<img src={data.Media.length ? data.Media[0].MediaURL : temp1} alt="" />
      </CustomLink>
    </figure>
    <div className="listing-data">
      <div className="listing-text">
        <h3>
          <CustomLink to={`/property/${data.ListingId}`}>
            {data.StreetNumber} {data.StreetName} {data.City}
          </CustomLink>
        </h3>
        <PropertyLocation />
        {
          data.LivingArea
          ? <div className="listing-sqft">
            <strong>{data.LivingArea}</strong> sqft
          </div>
          : null
        }
      </div>
      <div className="listing-focus">
        <CustomLink className="button size-s" to={`/property/${data.ListingId}`}>
          Open
        </CustomLink>
        <PropertyPrice value={data.ListPrice} />
      </div>
    </div>
  </article>
}
