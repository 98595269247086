import { useState, useEffect } from 'react'
import axios from 'axios'
import CustomLink from './CustomLink'
import { formatPhoneNumber } from '../helpers'
import { Magnifier as MagnifierIcon } from '../icons'

export default function SearchBoxDashboard() {
	const [users, setUsers] = useState([])
	const [search, setSearch] = useState('')
	const [matches, setMatches] = useState([])

	useEffect(() => {
		axios.get('/users').then(({data}) => setUsers(data.data.filter(({type}) => type === '1on1user')))
	}, [])

	useEffect(() => {
		const s = search.toLowerCase().trim()
		setMatches(users.filter(({ name, phone, email }) => name.toLowerCase().indexOf(s) !== -1 || phone.toLowerCase().indexOf(s) !== -1 || email.toLowerCase().indexOf(s) !== -1))
	}, [users, search])

	return <form>
    <input 
    	type="text" 
    	value={search}
    	onChange={({target}) => setSearch(target.value)}
    	placeholder="Search..." 
  	/>
    <button type="submit" className="button bullet">
      <MagnifierIcon />
    </button>
    {
    	search && matches.length
    	? <ul className="search-results">
		    {
		    	matches.map(user => <li key={user.id}>
		    		<CustomLink to={`/dashboard/users/${user.id}`}>
			    		{user.name} &nbsp;&nbsp; ({formatPhoneNumber(user.phone)})
			    		<br/>
			    		{user.email}
		    		</CustomLink>
		    	</li>)
		    }
	    </ul>
	    : null
    }
  </form>
}
