import { useEffect, useRef } from 'react'
import CtaBox from '../components/CtaBox'
import TextAndImageSection from '../components/TextAndImageSection'
import { initAnimationObserver } from '../helpers'
import CustomLink from '../components/CustomLink'

import aboutUsImage1 from '../img/about-01.jpg'
import aboutUsImage2 from '../img/about-02.jpg'
import icon1 from '../img/icon05.png'
import icon2 from '../img/icon06.png'
import icon3 from '../img/icon07.png'

import iconStep1 from '../img/icon-step1.png'
import iconStep2 from '../img/icon-step2.png'
import iconStep3 from '../img/icon-step3.png'
import iconStep4 from '../img/icon-step4.png'

export default function About() {
  const benefitsElement = useRef()

  useEffect(() => initAnimationObserver(benefitsElement), [benefitsElement])

  return <>
    <div className="page-title">
      <h2>About</h2>
    </div>
    <div className="site-content">
      <TextAndImageSection
        text={
          <>
            <h2>About Us</h2>
            <p>At 1on1 Home, we're more than just a real estate agency; we're your partners in making memories and finding new beginnings in the vibrant South Florida market. Our dedicated agents, recognized as market experts and award-winning professionals, work tirelessly to ensure you get top dollar for your property.</p>
            <p>Plus, we've got a secret sauce – we save home sellers thousands of dollars in unnecessary fees during closing. So, you can embark on your next adventure with confidence and extra funds to make it truly memorable.</p>
            <div className="free-consultation button">
              <CustomLink to="/contact">Inquire Now</CustomLink>
            </div>
          </>
        }
        image={<img src={aboutUsImage1} alt="About us media" />}
      />

      <TextAndImageSection 
        additionalClass="flipped"
      />
    </div>

    <div className="benefits-box" ref={benefitsElement}>
      <div className="site-content">
        <h2>How it works</h2>
        <div className="benefits-box-list">
          <div className="items-row">
            <div className="benefits-box-item">
              <figure>
                <img src={iconStep1} alt="Step 1" />
              </figure>
              <h3>Step 1</h3>
              <div>Enter your home's address to discover your savings and sign up for a free account.</div>
            </div>
            <div className="benefits-box-item">
              <figure>
                <img src={iconStep2} alt="Step 2" />
              </figure>
              <h3>Step 2</h3>
              <div>Connect directly with your local 1on1 Home agent, your guiding partner throughout the entire process. Rest assured, we handle it ALL for you.</div>
            </div>
          </div>
          <div className="items-row">
            <div className="benefits-box-item">
              <figure>
                <img src={iconStep3} alt="Step 3" />
              </figure>
              <h3>Step 3</h3>
              <div>Maximize views and drive prices up with complete marketing exposure across all platforms where home buyers are actively searching.</div>
            </div>
            <div className="benefits-box-item">
              <figure>
                <img src={iconStep4} alt="Step 4" />
              </figure>
              <h3>Step 4</h3>
              <div>Watch offers land directly in your account and collaborate with your agent to choose the highest and best one.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="site-content">
      <div className="values-box">
        <h2>Our Values</h2>
        <div className="values-box-list">
          <div className="values-box-item">
            <figure>
              <img src={icon1} alt="Maximizing Profitability" />
            </figure>
            <h3>Maximizing Profitability</h3>
            <div>At 1on1 Home, we're driven by the core value of maximizing profitability for home sellers. Our platform was designed by agents who understand the importance of helping homeowners make the most money possible from their property transactions. We are committed to utilizing our expertise and resources to ensure our clients achieve top dollar for their homes.</div>
          </div>
          <div className="values-box-item">
            <figure>
              <img src={icon2} alt="Empowering Home Sellers" />
            </figure>
            <h3>Empowering Home Sellers</h3>
            <div>We believe in empowering home sellers with the knowledge and tools they need to take control of their real estate transactions. Our platform facilitates direct communication with agents, allowing sellers to collaborate and make informed decisions, ultimately leading to better outcomes and financial success.</div>
          </div>
          <div className="values-box-item">
            <figure>
              <img src={icon3} alt="Transparency and Trust" />
            </figure>
            <h3>Transparency and Trust</h3>
            <div>Transparency and trust are fundamental to our mission. We are dedicated to providing a transparent and trustworthy experience for our clients. We aim to revolutionize the home-selling process by eliminating unnecessary fees and hidden costs, ensuring that our clients have complete confidence in every step of their journey with 1on1 Home.</div>
          </div>
        </div>
      </div>

      <TextAndImageSection
        text={
          <>
            <h2>Community Involvement</h2>
            <p>At 1on1 Home, we're not only deeply committed to the South Florida real estate market, but we also have a strong desire to give back to our community. Through active involvement and partnerships across many platforms, we aim to contribute positively while ensuring the best outcomes for our clients. Our vision is to create a win-win scenario where our success fuels meaningful support for the communities we serve.</p>
          </>
        }
        image={<img src={aboutUsImage2} alt="Community involvement media" style={{ objectFit: 'cover' }} />}
      />
    </div>

    {/*
    <div className="team-box">
      <div className="site-content">
        <h2>Meet Our team</h2>
        <div className="team-box-list">
          <div className="team-box-item">
            <figure>
              <img src={ourMissionImage} alt="Team media" />
            </figure>
            <h3>John Doe</h3>
            <h4>Founder and CEO</h4>
            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus sit nunc vestibulum.</div>
          </div>
          <div className="team-box-item">
            <figure>
              <img src={ourMissionImage} alt="Team media" />
            </figure>
            <h3>Suzanne King</h3>
            <h4>Agent</h4>
            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus sit nunc vestibulum.</div>
          </div>
          <div className="team-box-item">
            <figure>
              <img src={ourMissionImage} alt="Team media" />
            </figure>
            <h3>Mark Lu</h3>
            <h4>Agent</h4>
            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus sit nunc vestibulum.</div>
          </div>
        </div>
      </div>
    </div>

    <div className="testimonials">
      <div className="site-content">
        <div className="head-bar">
          <h2>Testimonials</h2>
          <div className="head-bar-buttons">
            <PrevButton />
            <NextButton />
          </div>
        </div>
        <div className="testimonials-list">
          <div className="testimonials-item">
            <figure>
              <img src={ourMissionImage} alt="Testimonial media" />
            </figure>
            <div className="testimonials-item-text">
              <div className="testimonials-item-top">
                <address>John Doe</address>
                <div>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
              </div>
              <div>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum nunc nullam rutrum sed sollicitudin euismod nisl phasellus sit. Odio felis, pellentesque viverra amet"
              </div>
            </div>
          </div>
          <div className="testimonials-item">
            <figure>
              <img src={ourMissionImage} alt="Testimonial media" />
            </figure>
            <div className="testimonials-item-text">
              <div className="testimonials-item-top">
                <address>John Doe</address>
                <div>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
              </div>
              <div>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum nunc nullam rutrum sed sollicitudin euismod nisl phasellus sit. Odio felis, pellentesque viverra amet"
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    */}

    <div className="site-content">
      <CtaBox />
    </div>
  </>
}
