import { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'

export default function Properties() {
	const [user, setUser] = useState(null)
	const userId = localStorage.getItem('1on1Home_userId')

	useEffect(() => {
		axios.get(`/users/${userId}`).then(({data}) => setUser(data.data))
	},[userId])

  return <>
  	<h2 className="admin-page-title">Properties</h2>
  	<div className="scrollable-holder">
			<table className="scrollable">
				<thead>
					<tr>
		  			<th>
		  				Property ID
		  				{/*<ChevronIcon />*/}
	  				</th>
	  				<th>
		  				Address
		  				{/*<ChevronIcon />*/}
	  				</th>
		  			<th>
		  				Set Date
		  				{/*<ChevronIcon />*/}
	  				</th>
	  				{/*
		  			<th>
		  				Offers Date
		  				<ChevronIcon />
						</th>
		  			<th>
		  				Current Price
		  				<ChevronIcon />
	  				</th>
		  			<th>
		  				Offer Price
		  				<ChevronIcon />
	  				</th>
		  			<th>
		  				Net proceeds if sale
		  				<ChevronIcon />
	  				</th>
		  			<th>Actions</th>
		  			*/}
					</tr>
				</thead>
				<tbody>
					{
						(user && user.properties ? user.properties : []).map((property, index) => <tr key={property.id}>
				      <td>00{index+1}</td>
				      <td>{JSON.parse(property.payload).formatted_address}</td>
				      <td>{moment(property.created_at).format('MM/DD/y h:mma').toLowerCase()}</td>
				      {/*
				      <td>08/05/2023</td>
				      <td>-</td>
				      <td className="price">-
				      	<span>$1.199.00</span>
				      	<small>-0.56%</small>
				      	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0_119_1369)">
										<path d="M12.7617 10.1396L7.62036 4.99824L4.9144 7.7042L0.855469 3.64526" stroke="#FF0000" strokeWidth="1.08238" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M9.51367 10.1395H12.7608V6.89233" stroke="#FF0000" strokeWidth="1.08238" strokeLinecap="round" strokeLinejoin="round"/>
									</g>
									<defs>
										<clipPath id="clip0_119_1369">
											<rect width="12.9886" height="12.9886" fill="white" transform="translate(0.314453 0.398193)"/>
										</clipPath>
									</defs>
								</svg>
				      </td>
				      <td>-</td>
				      <td className="action-buttons">
				      	<EyeIcon />
				      	<TrashIcon />
				      </td>
				      */}
			      </tr>)
					}
					{
						!(user && user.properties ? user.properties : []).length
						? <tr>
							<td colSpan="3">
								You have no properties listed at the moment...
							</td>
						</tr>
						: null
					}
		    </tbody>
	    </table>
    </div>
  </>
}
