import { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import CustomLink from '../components/CustomLink'
import useUserPermissions from '../hooks/useUserPermissions'
import {
	Eye as EyeIcon,
	Trash as TrashIcon 
} from '../icons'

export default function Posts() {
	const [posts, setPosts] = useState([])
	const [loading, setLoading] = useState(true)
	const { isAdmin } = useUserPermissions()

	useEffect(() => {
		axios.get('/posts').then(({data}) => {
			setPosts(data.data)
			setLoading(false)
		})
	}, [])

	if (!isAdmin) {
		return null
	}

	return <>
  	<div className="admin-page-head">
	  	<h2 className="admin-page-title">Blog</h2>
	  	<CustomLink to="/dashboard/posts/new" className="button">Add new post</CustomLink>
  	</div>
  	<div className="scrollable-holder">
			<table className="scrollable">
				<thead>
					<tr>
		  			<th>Post ID</th>
		  			<th>Title</th>
		  			<th>Date</th>
		  			<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{
						posts.map(post => <tr key={post.id}>
				      <td>{post.id}</td>
				      <td>{post.title}</td>
				      <td>{moment(post.created_at).format('MM/DD/y - h:mma').toLowerCase()}</td>
				      <td className="action-buttons">
				      	<CustomLink to={`/article/${post.id}`}>
				      		<EyeIcon />
				      	</CustomLink>
				      	&nbsp;&nbsp;&nbsp;
				      	<span className="random-button" onClick={() => {
				      		if (window.confirm('Are you sure?')) {
				      			axios.post(`/posts/delete/${post.id}`).then(() => {
				      				axios.get('/posts').then(({data}) => setPosts(data.data))
				      			})
				      		}
				      	}}>
				      		<TrashIcon />
			      		</span>
				      </td>
			      </tr>)
					}

					{
						!posts.length && !loading
						? <tr>
							<td colSpan="4">
								You have no posts received at the moment...
							</td>
						</tr>
						: null
					}
		    </tbody>
	    </table>
    </div>
  </>
}
