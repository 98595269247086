import PropertyPrice from './PropertyPrice'
import PropertyLocation from './PropertyLocation'

import temp1 from '../img/temp1.jpg'

export default function PropertyPlaceholder({ fake=false, hidePrice=false }) {
  return <article style={fake ? { opacity: 0 } : {}}>
    <figure>
      <img src={temp1} alt="" />
    </figure>
    <div className="listing-data">
      <div className="listing-text">
        <h3>Property Name</h3>
        <PropertyLocation />
      </div>
      {
        hidePrice
        ? null
        : <PropertyPrice />
      }
    </div>
  </article>
}
