import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Article from './Article'
import CustomLink from './CustomLink'
import { initAnimationObserver } from '../helpers'

export default function BlogArticles({ title='Our Blog' }) {
  const listingElement = useRef()
  const [posts, setPosts] = useState([])

  useEffect(() => initAnimationObserver(listingElement), [listingElement])

  useEffect(() => {
    axios.get(`/posts`).then(({data}) => setPosts(data.data.slice(0,3)))
  }, [])

  return <div className="listing blog-listing animated-listing" ref={listingElement}>
    <div className="head-bar">
      <h2>{title}</h2>
      <CustomLink to="/blog" className="button bordered">See All</CustomLink>
    </div>
    <div className="listing-articles">
      {
        posts.map(post => <Article key={post.id} article={post} />)
      }
    </div>
  </div>
}
