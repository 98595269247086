import React from 'react'
import { Link } from 'react-router-dom'

export default function CustomLink(props) {
  return <Link {...props} onClick={() => {
    window.scrollTo(0, 0)

    if (props.onClick) {
      props.onClick()
    }
  }}>
    {props.children}
  </Link>
}
