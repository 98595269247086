const Chevron = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M9 7.5L6 4.5L3 7.5" stroke="#777E90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const Eye = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z" stroke="#B1B5C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	<path d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z" stroke="#B1B5C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const House = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 20H4M4 20H10M4 20V11.452C4 10.918 4 10.651 4.065 10.402C4.12256 10.1819 4.21725 9.97325 4.345 9.785C4.49 9.572 4.691 9.395 5.093 9.044L9.894 4.842C10.64 4.19 11.013 3.864 11.432 3.74C11.802 3.63 12.197 3.63 12.567 3.74C12.987 3.864 13.361 4.19 14.107 4.844L18.907 9.044C19.309 9.396 19.51 9.572 19.655 9.784C19.782 9.974 19.877 10.182 19.935 10.402C20 10.651 20 10.918 20 11.452V20M10 20H14M10 20V16C10 15.4696 10.2107 14.9609 10.5858 14.5858C10.9609 14.2107 11.4696 14 12 14C12.5304 14 13.0391 14.2107 13.4142 14.5858C13.7893 14.9609 14 15.4696 14 16V20M20 20H14M20 20H22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const Magnifier = () => <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.625 16.6249L13.1868 13.1867M13.1868 13.1867C13.775 12.5986 14.2415 11.9004 14.5598 11.132C14.878 10.3636 15.0419 9.53998 15.0419 8.70825C15.0419 7.87653 14.8781 7.05294 14.5598 6.28453C14.2415 5.51611 13.775 4.81791 13.1868 4.22979C12.5987 3.64167 11.9005 3.17515 11.1321 2.85686C10.3637 2.53858 9.5401 2.37476 8.70837 2.37476C7.87665 2.37476 7.05307 2.53858 6.28465 2.85686C5.51624 3.17515 4.81804 3.64167 4.22992 4.22979C3.04216 5.41756 2.37488 7.0285 2.37488 8.70825C2.37488 10.388 3.04216 11.9989 4.22992 13.1867C5.41768 14.3745 7.02863 15.0417 8.70837 15.0417C10.3881 15.0417 11.9991 14.3745 13.1868 13.1867Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const Star = () => <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M7.38674 2.58514C7.61834 2.04844 8.37935 2.04844 8.61096 2.58514L10.0643 5.95307C10.1624 6.18032 10.3783 6.33454 10.6251 6.35361L14.3522 6.64161C14.9501 6.68781 15.1877 7.43852 14.7252 7.82038L11.9223 10.1346C11.7246 10.2978 11.638 10.5599 11.6996 10.8088L12.5622 14.2944C12.7041 14.8676 12.0858 15.3286 11.5769 15.029L8.33706 13.1217C8.12833 12.9988 7.86937 12.9988 7.66063 13.1217L4.42081 15.029C3.91194 15.3286 3.29359 14.8676 3.43545 14.2944L4.29808 10.8088C4.35967 10.5599 4.27309 10.2978 4.07538 10.1346L1.27246 7.82038C0.809953 7.43852 1.04756 6.68781 1.64555 6.64161L5.37265 6.35361C5.61942 6.33454 5.83533 6.18032 5.93339 5.95307L7.38674 2.58514Z" fill="#0C5FDC"/>
</svg>

const Trash = () => <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M2.25 4.5H3.75H15.75" stroke="#B1B5C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	<path d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5" stroke="#B1B5C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	<path d="M7.5 8.25V12.75" stroke="#B1B5C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	<path d="M10.5 8.25V12.75" stroke="#B1B5C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export {
	Chevron,
	Eye,
	House,
	Magnifier,
	Star,
	Trash
}
