import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'
import axios from 'axios'
import CtaBox from '../components/CtaBox'
import PrevButton from '../components/PrevButton'
import NextButton from '../components/NextButton'
import Properties from '../components/Properties'
import ContactForm from '../components/ContactForm'
import PropertyPrice from '../components/PropertyPrice'
import PropertyLocation from '../components/PropertyLocation'
import ourMissionImage from '../img/our-mission.jpg'

const PlusIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M6 12H18" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
	<path d="M12 18V6" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export default function Property() {
	const { id } = useParams()
	const [expandedSection, setExpandedSection] = useState('')
	const [property, setProperty] = useState(null)
	const [propertyData, setPropertyData] = useState(null)
	const [slider, setSlider] = useState(null)

	const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

	useEffect(() => {
		axios.get(`/properties/rets/imports/${id}`).then(({data}) => {
			setProperty(data.data)
			setPropertyData(data.data.data)
		})
	}, [id])

	if (!property) {
		return false
	}

  return <>
  	<div className="site-content">
  		<div className="property-super">
  			<div>
		  		<div className="property-title">
		  			<h2>{propertyData.StreetNumber} {propertyData.StreetName} {propertyData.City}</h2>
		  			{/*
		  			<span className="button bullet gray">
			  			<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12.5 8.44194C11.772 7.12994 10.373 6.24194 8.766 6.24194C6.41 6.24194 4.5 8.15194 4.5 10.5089C4.5 14.1249 10.632 17.7409 12.5 18.7589C14.368 17.7419 20.5 14.1249 20.5 10.5089C20.5 8.15294 18.59 6.24194 16.233 6.24194C14.627 6.24194 13.228 7.12994 12.5 8.44194Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
						</span>
						<span className="button bullet gray">
							<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5.5 12.5V19.7C5.5 20.1774 5.68437 20.6352 6.01256 20.9728C6.34075 21.3104 6.78587 21.5 7.25 21.5H17.75C18.2141 21.5 18.6592 21.3104 18.9874 20.9728C19.3156 20.6352 19.5 20.1774 19.5 19.7V12.5M16 7.1L12.5 3.5M12.5 3.5L9 7.1M12.5 3.5V15.2" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
						</span>
						*/}
					</div>

		  		<PropertyLocation 
		  			text={`${propertyData.StreetNumber} ${propertyData.StreetName} ${propertyData.City}`}
		  		/>
	  		</div>

	  		<PropertyPrice 
					mode="tag"
					value={propertyData.ListPrice}
				/>
  		</div>

  		<div className="property-top">
  			{
  				propertyData.Media.length
  				? <div className="property-slider">
  					<Slider {...settings} ref={slider => setSlider(slider)}>
  						{propertyData.Media.map((media, index) => <div key={index}>
  							<img src={media.MediaURL} alt="Property media" />
							</div>)}
	          </Slider>
			  		<PrevButton onClick={() => slider.slickPrev()} />
	          <NextButton onClick={() => slider.slickNext()} />
					</div>
					: null
  			}
			</div>

			<div className="property-page">
				<div className="property-main">
					<div className="property-size">
						{
	  					propertyData.LotSizeSquareFeet
	  					? <div>
			  				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0_27_1027)">
										<path d="M5 17H19C21 17 22 16 22 14V10C22 8 21 7 19 7H5C3 7 2 8 2 10V14C2 16 3 17 5 17Z" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M18 7V12" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M6 7V11" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M10.05 7L10 12" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M14 7V10" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
									</g>
									<defs>
										<clipPath id="clip0_27_1027">
											<rect width="24" height="24" fill="white"/>
										</clipPath>
									</defs>
								</svg>
								<span>
									<strong>{propertyData.LotSizeSquareFeet}</strong> sqft lot
								</span>
							</div>
							: null
	  				}

	  				{
	  					propertyData.LivingArea
	  					? <div>
			  				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clipPath="url(#clip0_27_1027)">
										<path d="M5 17H19C21 17 22 16 22 14V10C22 8 21 7 19 7H5C3 7 2 8 2 10V14C2 16 3 17 5 17Z" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M18 7V12" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M6 7V11" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M10.05 7L10 12" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M14 7V10" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
									</g>
									<defs>
										<clipPath id="clip0_27_1027">
											<rect width="24" height="24" fill="white"/>
										</clipPath>
									</defs>
								</svg>
								<span>
									<strong>{propertyData.LivingArea}</strong> sqft living
								</span>
							</div>
							: null
	  				}
						
						<div>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M17.333 12H21.333V15.999C21.333 18.208 19.542 19.999 17.333 19.999H6.66699C4.45799 19.999 2.66699 18.208 2.66699 15.999V12H10.667M5.33299 21.332V19.769M18.667 21.332V19.769M5.33299 12V5.33497C5.33299 3.86197 6.52699 2.66797 7.99999 2.66797C9.47299 2.66797 10.667 3.86197 10.667 5.33497M10.667 10.667H17.334V15.999H10.667V10.667Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
				  		<span>
				  			<strong>{propertyData.BathroomsTotalInteger}</strong> bathrooms
			  			</span>
			  		</div>

			  		<div>
			  			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clipPath="url(#clip0_27_1049)">
									<path d="M5 17H19C21 17 22 16 22 14V10C22 8 21 7 19 7H5C3 7 2 8 2 10V14C2 16 3 17 5 17Z" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
									<path d="M18 7V12" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
									<path d="M6 7V11" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
									<path d="M10.05 7L10 12" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
									<path d="M14 7V10" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
								</g>
								<defs>
									<clipPath id="clip0_27_1049">
										<rect width="24" height="24" fill="white"/>
									</clipPath>
								</defs>
							</svg>
							<span>
			  				<strong>{propertyData.BedroomsTotal}</strong> bedrooms
			  			</span>
		  			</div>
		  		</div>

		  		<div className="property-description">
		  			{propertyData.Directions}
	  			</div>

		  		<div className="property-expanders">
		  			<div className="property-expander">
			  			<div className="property-expander-head" onClick={() => setExpandedSection(expandedSection === 'details' ? '' : 'details')}>
					  		<div>
						  		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 2V5" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M16 2V5" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M7 13H15" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M7 17H12" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
					  			<span className="size-s">Property details</span>
				  			</div>
				  			<PlusIcon />
			  			</div>
			  			{
			  				expandedSection === 'details'
			  				? <div className="property-expander-content">
				  				{propertyData.PublicRemarks}
			  				</div>
			  				: null
			  			}
						</div>

			  		<div className="property-expander">
			  			<div className="property-expander-head" onClick={() => setExpandedSection(expandedSection === 'houses' ? '' : 'houses')}>
				  			<div>
					  			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M2 22H22" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M2.9502 22L3.0002 9.96999C3.0002 9.35999 3.2902 8.78004 3.7702 8.40004L10.7702 2.95003C11.4902 2.39003 12.5002 2.39003 13.2302 2.95003L20.2302 8.39003C20.7202 8.77003 21.0002 9.34999 21.0002 9.96999V22" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round"/>
										<path d="M15.5 11H8.5C7.67 11 7 11.67 7 12.5V22H17V12.5C17 11.67 16.33 11 15.5 11Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M10 16.25V17.75" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M10.5 7.5H13.5" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
					  			<span className="size-s">Features</span>
				  			</div>
				  			<PlusIcon />
			  			</div>
			  			{
			  				expandedSection === 'houses'
			  				? <div className="property-expander-content">
			  					{
			  						['CommunityFeatures', 'ExteriorFeatures', 'InteriorFeatures', 'LotFeatures', 'ParkingFeatures', 'PoolFeatures', 'SecurityFeatures', 'Utilities'].map(key => {
			  							return <>
			  								<strong>{key.replace('Features', '')}</strong>: {(propertyData[key] || 'n/a').split(',').join(', ').replace(/([a-z])([A-Z])/g, '$1 $2')}
				  							<br/><br/>
			  							</>
			  						})
			  					}
			  				</div>
			  				: null
			  			}
						</div>

			  		{/*
			  		<div className="property-expander">
			  			<div className="property-expander-head" onClick={() => setExpandedSection(expandedSection === 'payment' ? '' : 'payment')}>
					  		<div>
						  		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M13 9H7" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M22.0002 10.9699V13.03C22.0002 13.58 21.5602 14.0299 21.0002 14.0499H19.0402C17.9602 14.0499 16.9702 13.2599 16.8802 12.1799C16.8202 11.5499 17.0602 10.9599 17.4802 10.5499C17.8502 10.1699 18.3602 9.94995 18.9202 9.94995H21.0002C21.5602 9.96995 22.0002 10.4199 22.0002 10.9699Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H7C4 20.5 2 18.5 2 15.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.50999 16.75 3.54999C19.33 3.84999 21 5.76 21 8.5V9.95001H18.92C18.36 9.95001 17.85 10.17 17.48 10.55Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
					  			<span className="size-s">Monthly payment</span>
				  			</div>
				  			<PlusIcon />
			  			</div>
			  			{
			  				expandedSection === 'payment'
			  				? <div className="property-expander-content">
				  				Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.
			  				</div>
			  				: null
			  			}
						</div>

			  		<div className="property-expander">
			  			<div className="property-expander-head" onClick={() => setExpandedSection(expandedSection === 'veterans' ? '' : 'veterans')}>
				  			<div>
					  			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12 15C15.7279 15 18.75 12.0899 18.75 8.5C18.75 4.91015 15.7279 2 12 2C8.27208 2 5.25 4.91015 5.25 8.5C5.25 12.0899 8.27208 15 12 15Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M7.51974 13.52L7.50977 20.9C7.50977 21.8 8.13977 22.24 8.91977 21.87L11.5998 20.6C11.8198 20.49 12.1898 20.49 12.4098 20.6L15.0998 21.87C15.8698 22.23 16.5098 21.8 16.5098 20.9V13.34" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
					  			<span className="size-s">Veterans &amp; military benefits</span>
				  			</div>
				  			<PlusIcon />
			  			</div>
			  			{
			  				expandedSection === 'veterans'
			  				? <div className="property-expander-content">
				  				Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.
			  				</div>
			  				: null
			  			}
						</div>

			  		<div className="property-expander">
			  			<div className="property-expander-head" onClick={() => setExpandedSection(expandedSection === 'history' ? '' : 'history')}>
				  			<div>
					  			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
					  			<span className="size-s">Property history</span>
				  			</div>
				  			<PlusIcon />
			  			</div>
			  			{
			  				expandedSection === 'history'
			  				? <div className="property-expander-content">
				  				Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.
			  				</div>
			  				: null
			  			}
						</div>

			  		<div className="property-expander">
			  			<div className="property-expander-head" onClick={() => setExpandedSection(expandedSection === 'neighborhood' ? '' : 'neighborhood')}>
				  			<div>
					  			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M2.07006 4.6C2.87006 1.14 8.08006 1.14 8.87006 4.6C9.34006 6.63 8.05006 8.35 6.93006 9.42C6.11006 10.2 4.82006 10.19 4.00006 9.42C2.89006 8.35 1.60006 6.63 2.07006 4.6Z" stroke="black" strokeWidth="1.5"/>
										<path d="M15.07 16.6C15.87 13.14 21.11 13.14 21.91 16.6C22.38 18.63 21.09 20.35 19.96 21.42C19.14 22.2 17.84 22.19 17.02 21.42C15.89 20.35 14.6 18.63 15.07 16.6Z" stroke="black" strokeWidth="1.5"/>
										<path d="M12.0002 5H14.6802C16.5302 5 17.3902 7.29 16.0002 8.51L8.01019 15.5C6.62019 16.71 7.48019 19 9.32019 19H12.0002" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M5.48622 5.5H5.49777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M18.4862 17.5H18.4978" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
					  			<span className="size-s">Neighborhood</span>
				  			</div>
				  			<PlusIcon />
			  			</div>
			  			{
			  				expandedSection === 'neighborhood'
			  				? <div className="property-expander-content">
				  				Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.
			  				</div>
			  				: null
			  			}
						</div>

			  		<div className="property-expander">
			  			<div className="property-expander-head" onClick={() => setExpandedSection(expandedSection === 'environment' ? '' : 'environment')}>
				  			<div>
					  			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M16.1698 10.06H7.82982C6.64982 10.06 6.23983 9.27 6.92983 8.31L11.0998 2.47001C11.5898 1.77001 12.4099 1.77001 12.8899 2.47001L17.0598 8.31C17.7598 9.27 17.3498 10.06 16.1698 10.06Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M17.5898 18H6.41986C4.83986 18 4.29985 16.95 5.22985 15.67L9.21984 10.06H14.7899L18.7798 15.67C19.7098 16.95 19.1698 18 17.5898 18Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M12 22V18" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
					  			<span className="size-s">Environment</span>
				  			</div>
				  			<PlusIcon />
			  			</div>
			  			{
			  				expandedSection === 'environment'
			  				? <div className="property-expander-content">
				  				Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.
			  				</div>
			  				: null
			  			}
						</div>

			  		<div className="property-expander">
			  			<div className="property-expander-head" onClick={() => setExpandedSection(expandedSection === 'schools' ? '' : 'schools')}>
				  			<div>
					  			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 22H23" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M19.7803 22.01V17.55" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M19.8001 10.89C18.5801 10.89 17.6001 11.87 17.6001 13.09V15.36C17.6001 16.58 18.5801 17.56 19.8001 17.56C21.0201 17.56 22.0001 16.58 22.0001 15.36V13.09C22.0001 11.87 21.0201 10.89 19.8001 10.89Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M2.1001 22V6.03003C2.1001 4.02003 3.10015 3.01001 5.09015 3.01001H11.3201C13.3101 3.01001 14.3001 4.02003 14.3001 6.03003V22" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M5.7998 8.25H10.7498" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M5.7998 12H10.7498" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M8.25 22V18.25" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
					  			<span className="size-s">Schools</span>
				  			</div>
				  			<PlusIcon />
			  			</div>
			  			{
			  				expandedSection === 'schools'
			  				? <div className="property-expander-content">
				  				Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.
			  				</div>
			  				: null
			  			}
						</div>
			  		<div className="property-expander">
			  			<div className="property-expander-head" onClick={() => setExpandedSection(expandedSection === 'nearby' ? '' : 'nearby')}>
				  			<div>
					  			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6 4C7.67 2.75 9.75 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 10.19 2.47999 8.48999 3.32999 7.01999L12 12" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
					  			<span className="size-s">Nearby home values</span>
				  			</div>
				  			<PlusIcon />
			  			</div>
			  			{
			  				expandedSection === 'nearby'
			  				? <div className="property-expander-content">
				  				Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.
			  				</div>
			  				: null
			  			}
						</div>
						*/}
					</div>
				</div>

				<div className="property-contact auth-box">
		  		<h3 className="size-s">Contact Agent</h3>

					<div className="property-agent">
						<img src={ourMissionImage} alt="Agent media" />
						<div>
							<div className="property-agent-name">Andy Gellert</div>
							<div className="property-agent-company">RE/MAX Direct</div>
						</div>
					</div>

					<ContactForm />
				</div>
			</div>

	    <Properties 
	    	max={3}
	    	exclude={id}
        retsData={true}
        title="Similar Properties"
      />
	    <CtaBox />
	  </div>
  </>
}
