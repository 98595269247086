import { useEffect, useRef } from 'react'
import { initAnimationObserver } from '../helpers'

import hdrPhotos from '../img/hdr-photos.jpg'
import aerialPhoto from '../img/aerial-photo.jpg'
import videoTours from '../img/video-tours.jpeg'
import virtualStaging from '../img/virtual-staging.jpg'
import duskImages from '../img/dusk-image.jpg'
import virtualTour from '../img/virtual-tour.jpg'
import iconDiamond from '../img/icon-diamond.svg'
import CustomLink from '../components/CustomLink'
import ContactForm from '../components/ContactForm'

export default function Services() {
	const animationBox1 = useRef()
	const animationBox2 = useRef()
	const animationBox3 = useRef()
	const animationBox4 = useRef()
	const animationBox5 = useRef()
	const animationBox6 = useRef()

	useEffect(() => initAnimationObserver(animationBox1), [animationBox1])
	useEffect(() => initAnimationObserver(animationBox2), [animationBox2])
	useEffect(() => initAnimationObserver(animationBox3), [animationBox3])
	useEffect(() => initAnimationObserver(animationBox4), [animationBox4])
	useEffect(() => initAnimationObserver(animationBox5), [animationBox5])
	useEffect(() => initAnimationObserver(animationBox6), [animationBox6])

  return <>
  	<div className="page-title services">
      <h2>Services</h2>
    </div>
    <div className="site-content services">

			<div className="our-mission" ref={animationBox1}>
    		<div className="our-mission-text hdr-photos">
    			<span className="spot-light standard">Starting at $145</span>
          <h2>HDR Photos</h2>
          <p>As many photos needed to tell the home's story. Crisp exterior and interior images that will make the property sparkle!</p>
          <div className="price-list">
          	<div className="line">
          		<div className="icon">
	          		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M13.4996 21V13.5C13.4996 13.3011 13.5786 13.1103 13.7193 12.9697C13.8599 12.829 14.0507 12.75 14.2496 12.75H17.2496C17.4485 12.75 17.6393 12.829 17.78 12.9697C17.9206 13.1103 17.9996 13.3011 17.9996 13.5V21M13.4996 21H2.35962M13.4996 21H17.9996M17.9996 21H21.6396M20.2496 21V9.349C20.6482 9.11891 20.9885 8.80019 21.2442 8.41751C21.4999 8.03484 21.6641 7.59846 21.7242 7.14215C21.7842 6.68584 21.7384 6.22184 21.5904 5.78605C21.4424 5.35026 21.1961 4.95436 20.8706 4.629L19.6806 3.44C19.3996 3.15862 19.0183 3.00035 18.6206 3H5.37762C4.97993 3.00035 4.59866 3.15862 4.31762 3.44L3.12862 4.628C2.80386 4.95374 2.55824 5.3497 2.41069 5.78537C2.26315 6.22104 2.21762 6.68477 2.27762 7.14082C2.33762 7.59686 2.50155 8.03304 2.75676 8.41571C3.01197 8.79839 3.35166 9.11735 3.74962 9.348M3.74962 20.999V9.35C4.34965 9.69598 5.05087 9.82376 5.73437 9.71167C6.41786 9.59958 7.04154 9.25451 7.49962 8.735C7.78092 9.05445 8.12712 9.31022 8.51512 9.48525C8.90311 9.66028 9.32398 9.75054 9.74962 9.75C10.6456 9.75 11.4496 9.357 11.9996 8.734C12.2808 9.05363 12.627 9.30959 13.015 9.48479C13.403 9.66 13.8239 9.75041 14.2496 9.75C15.1456 9.75 15.9496 9.357 16.4996 8.734C16.9578 9.25335 17.5816 9.59822 18.2651 9.71013C18.9486 9.82204 19.6497 9.6941 20.2496 9.348M6.74962 17.999H10.4996C10.6985 17.999 10.8893 17.92 11.03 17.7793C11.1706 17.6387 11.2496 17.4479 11.2496 17.249V13.5C11.2496 13.3011 11.1706 13.1103 11.03 12.9697C10.8893 12.829 10.6985 12.75 10.4996 12.75H6.74962C6.55071 12.75 6.35995 12.829 6.21929 12.9697C6.07864 13.1103 5.99962 13.3011 5.99962 13.5V17.25C5.99962 17.665 6.33562 17.999 6.74962 17.999Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
							<div className="column">
								<span className="price-tag">$145 (with Aerials $220)</span>
          			<span className="space">Up To 3,000 ft2</span>
							</div>
          	</div>
          	<div className="line">
          		<div className="icon">
	          		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M3.75 21H20.25M4.5 3H19.5M5.25 3V21M18.75 3V21M9 6.75H10.5M9 9.75H10.5M9 12.75H10.5M13.5 6.75H15M13.5 9.75H15M13.5 12.75H15M9 21V17.625C9 17.004 9.504 16.5 10.125 16.5H13.875C14.496 16.5 15 17.004 15 17.625V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
							<div className="column">
	          		<span className="price-tag">$195 (with Aerials $270)</span>
	          		<span className="space">3,001 - 6,000 ft2</span>
	          	</div>
          	</div>
          	<div className="line">
          		<div className="icon">
	          		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M2.25 21H21.75M3.75 3V21M14.25 3V21M20.25 7.5V21M6.75 6.75H7.5M6.75 9.75H7.5M6.75 12.75H7.5M10.5 6.75H11.25M10.5 9.75H11.25M10.5 12.75H11.25M6.75 21V17.625C6.75 17.004 7.254 16.5 7.875 16.5H10.125C10.746 16.5 11.25 17.004 11.25 17.625V21M3 3H15M14.25 7.5H21M17.25 11.25H17.258V11.258H17.25V11.25ZM17.25 14.25H17.258V14.258H17.25V14.25ZM17.25 17.25H17.258V17.258H17.25V17.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
							<div className="column">
	          		<span className="price-tag">$245 (with Aerials $320)</span>
	          		<span className="space">6,001 - 8,000 ft2</span>
	          	</div>
          	</div>
          	<div className="free-consultation button">
          		<CustomLink to="/contact">Inquire Now</CustomLink>
        		</div>
          </div>
    		</div>
    		<img src={hdrPhotos} alt="HDR Photos" /> 
  		</div>

 			<div className="our-mission flipped" ref={animationBox2}>
    		<div className="our-mission-text aerial-photos">
    			<div className="first-line">
	          <h2>Aerial Photography</h2>
	          <span className="spot-light blue">$125</span>
	        </div>
          <p>Aerial photos capture the entire property, including its layout, size, and location, in a single shot.</p>
          <div className="price-list">
          	<div className="line">
          		<div className="icon">
	          		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 6C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75H20.25C20.8467 3.75 21.419 3.98705 21.841 4.40901C22.2629 4.83097 22.5 5.40326 22.5 6V18C22.5 18.5967 22.2629 19.169 21.841 19.591C21.419 20.0129 20.8467 20.25 20.25 20.25H3.75C3.15326 20.25 2.58097 20.0129 2.15901 19.591C1.73705 19.169 1.5 18.5967 1.5 18V6ZM3 16.06V18C3 18.414 3.336 18.75 3.75 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18C21 16.7579 20.5064 15.5666 19.628 14.6885L18.31 13.371C18.0287 13.0901 17.6475 12.9323 17.25 12.9323C16.8525 12.9323 16.4713 13.0901 16.19 13.371C15.7041 13.8564 15.7038 14.6438 16.1895 15.1295L16.28 15.22C16.3537 15.2887 16.4128 15.3715 16.4538 15.4635C16.4948 15.5555 16.5168 15.6548 16.5186 15.7555C16.5204 15.8562 16.5018 15.9562 16.4641 16.0496C16.4264 16.143 16.3703 16.2278 16.299 16.299C16.2278 16.3703 16.143 16.4264 16.0496 16.4641C15.9562 16.5018 15.8562 16.5204 15.7555 16.5186C15.6548 16.5168 15.5555 16.4948 15.4635 16.4538C15.3715 16.4128 15.2887 16.3537 15.22 16.28L10.06 11.121C9.77875 10.8401 9.3975 10.6823 9 10.6823C8.6025 10.6823 8.22125 10.8401 7.94 11.121L3.00071 16.0603C3.00045 16.0606 3 16.0604 3 16.06ZM13.125 8.25C13.125 7.95163 13.2435 7.66548 13.4545 7.4545C13.6655 7.24353 13.9516 7.125 14.25 7.125C14.5484 7.125 14.8345 7.24353 15.0455 7.4545C15.2565 7.66548 15.375 7.95163 15.375 8.25C15.375 8.54837 15.2565 8.83452 15.0455 9.0455C14.8345 9.25647 14.5484 9.375 14.25 9.375C13.9516 9.375 13.6655 9.25647 13.4545 9.0455C13.2435 8.83452 13.125 8.54837 13.125 8.25Z" fill="url(#paint0_linear_344_362)"/>
									<defs>
										<linearGradient id="paint0_linear_344_362" x1="12" y1="20.25" x2="12" y2="3.75" gradientUnits="userSpaceOnUse">
											<stop stop-color="#0C5FDC"/>
											<stop offset="1" stop-color="#73BAFB"/>
										</linearGradient>
									</defs>
								</svg>
							</div>
							<div className="column">
								<span className="price-tag">8-12 photos</span>
							</div>
          	</div>
          	<div className="line">
          		<div className="icon">
	          		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M12 9C11.0054 9 10.0516 9.39509 9.34835 10.0983C8.64509 10.8016 8.25 11.7554 8.25 12.75C8.25 13.7446 8.64509 14.6984 9.34835 15.4017C10.0516 16.1049 11.0054 16.5 12 16.5C12.9946 16.5 13.9484 16.1049 14.6517 15.4017C15.3549 14.6984 15.75 13.7446 15.75 12.75C15.75 11.7554 15.3549 10.8016 14.6517 10.0983C13.9484 9.39509 12.9946 9 12 9Z" fill="url(#paint0_linear_344_366)"/>
									<path fill-rule="evenodd" clip-rule="evenodd" d="M9.344 3.07097C11.1134 2.97594 12.8866 2.97594 14.656 3.07097C15.623 3.12297 16.486 3.65597 16.988 4.46097L17.809 5.77797C18.049 6.16097 18.454 6.42097 18.919 6.48797C19.305 6.54197 19.689 6.60097 20.071 6.66497C21.503 6.90397 22.5 8.15797 22.5 9.57397V18C22.5 18.7956 22.1839 19.5587 21.6213 20.1213C21.0587 20.6839 20.2956 21 19.5 21H4.5C3.70435 21 2.94129 20.6839 2.37868 20.1213C1.81607 19.5587 1.5 18.7956 1.5 18V9.57397C1.5 8.15797 2.497 6.90397 3.929 6.66497C4.311 6.60097 4.695 6.54197 5.08 6.48697C5.30561 6.45678 5.52189 6.37757 5.71364 6.25492C5.9054 6.13227 6.06799 5.96913 6.19 5.77697L7.012 4.46197C7.2605 4.06003 7.60216 3.72385 8.00808 3.4819C8.414 3.23995 8.87223 3.09834 9.344 3.07097ZM6.75 12.75C6.75 11.3576 7.30312 10.0222 8.28769 9.03766C9.27225 8.0531 10.6076 7.49997 12 7.49997C13.3924 7.49997 14.7277 8.0531 15.7123 9.03766C16.6969 10.0222 17.25 11.3576 17.25 12.75C17.25 14.1424 16.6969 15.4777 15.7123 16.4623C14.7277 17.4468 13.3924 18 12 18C10.6076 18 9.27225 17.4468 8.28769 16.4623C7.30312 15.4777 6.75 14.1424 6.75 12.75ZM18.75 11.25C18.9489 11.25 19.1397 11.171 19.2803 11.0303C19.421 10.8897 19.5 10.6989 19.5 10.5C19.5 10.3011 19.421 10.1103 19.2803 9.96964C19.1397 9.82899 18.9489 9.74997 18.75 9.74997C18.5511 9.74997 18.3603 9.82899 18.2197 9.96964C18.079 10.1103 18 10.3011 18 10.5C18 10.6989 18.079 10.8897 18.2197 11.0303C18.3603 11.171 18.5511 11.25 18.75 11.25Z" fill="url(#paint1_linear_344_366)"/>
									<defs>
										<linearGradient id="paint0_linear_344_366" x1="12" y1="16.5" x2="12" y2="9" gradientUnits="userSpaceOnUse">
											<stop stop-color="#0C5FDC"/>
											<stop offset="1" stop-color="#73BAFB"/>
										</linearGradient>
										<linearGradient id="paint1_linear_344_366" x1="12" y1="21" x2="12" y2="2.99969" gradientUnits="userSpaceOnUse">
											<stop stop-color="#0C5FDC"/>
											<stop offset="1" stop-color="#73BAFB"/>
										</linearGradient>
									</defs>
								</svg>
							</div>
							<div className="column">
								<span className="price-tag">Only $75 when added to a shoot</span>
							</div>
          	</div>
          	<div className="free-consultation button">
          		<CustomLink to="/contact">Inquire Now</CustomLink>
        		</div>
          </div>
    		</div>
      	<img src={aerialPhoto} alt="Aerial Photography" /> 
  		</div>

  		<div className="our-mission" ref={animationBox3}>
  			<button className="play-btn">
  				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M6.7998 7.53734C6.7998 5.63601 8.83847 4.43067 10.5051 5.34667L25.8918 13.8107C27.6185 14.76 27.6185 17.2413 25.8918 18.1907L10.5065 26.6547C8.8398 27.5707 6.80114 26.3653 6.80114 24.464L6.7998 7.53734Z" fill="white"/>
					</svg>
  				View Example
  			</button>
    		<div className="our-mission-text video-tours">
    			<span className="spot-light standard">Starting at $250</span>
          <h2>Video Tours</h2>
          <p>Video tours guide potential buyers through living spaces in a relatable way, and engage visitors on your listings longer. Add to any photo shoot for $250.</p>
    			<div className="price-list">

          	<div className="line">
          		<div className="icon">
	          		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M13.4996 21V13.5C13.4996 13.3011 13.5786 13.1103 13.7193 12.9697C13.8599 12.829 14.0507 12.75 14.2496 12.75H17.2496C17.4485 12.75 17.6393 12.829 17.78 12.9697C17.9206 13.1103 17.9996 13.3011 17.9996 13.5V21M13.4996 21H2.35962M13.4996 21H17.9996M17.9996 21H21.6396M20.2496 21V9.349C20.6482 9.11891 20.9885 8.80019 21.2442 8.41751C21.4999 8.03484 21.6641 7.59846 21.7242 7.14215C21.7842 6.68584 21.7384 6.22184 21.5904 5.78605C21.4424 5.35026 21.1961 4.95436 20.8706 4.629L19.6806 3.44C19.3996 3.15862 19.0183 3.00035 18.6206 3H5.37762C4.97993 3.00035 4.59866 3.15862 4.31762 3.44L3.12862 4.628C2.80386 4.95374 2.55824 5.3497 2.41069 5.78537C2.26315 6.22104 2.21762 6.68477 2.27762 7.14082C2.33762 7.59686 2.50155 8.03304 2.75676 8.41571C3.01197 8.79839 3.35166 9.11735 3.74962 9.348M3.74962 20.999V9.35C4.34965 9.69598 5.05087 9.82376 5.73437 9.71167C6.41786 9.59958 7.04154 9.25451 7.49962 8.735C7.78092 9.05445 8.12712 9.31022 8.51512 9.48525C8.90311 9.66028 9.32398 9.75054 9.74962 9.75C10.6456 9.75 11.4496 9.357 11.9996 8.734C12.2808 9.05363 12.627 9.30959 13.015 9.48479C13.403 9.66 13.8239 9.75041 14.2496 9.75C15.1456 9.75 15.9496 9.357 16.4996 8.734C16.9578 9.25335 17.5816 9.59822 18.2651 9.71013C18.9486 9.82204 19.6497 9.6941 20.2496 9.348M6.74962 17.999H10.4996C10.6985 17.999 10.8893 17.92 11.03 17.7793C11.1706 17.6387 11.2496 17.4479 11.2496 17.249V13.5C11.2496 13.3011 11.1706 13.1103 11.03 12.9697C10.8893 12.829 10.6985 12.75 10.4996 12.75H6.74962C6.55071 12.75 6.35995 12.829 6.21929 12.9697C6.07864 13.1103 5.99962 13.3011 5.99962 13.5V17.25C5.99962 17.665 6.33562 17.999 6.74962 17.999Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
							<div className="column">
								<span className="price-tag">$250 (with Aerial Fottage $300)</span>
          			<span className="space">Up To 3,000 ft2</span>
							</div>
          	</div>

          	<div className="line">
          		<div className="icon">
	          		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M3.75 21H20.25M4.5 3H19.5M5.25 3V21M18.75 3V21M9 6.75H10.5M9 9.75H10.5M9 12.75H10.5M13.5 6.75H15M13.5 9.75H15M13.5 12.75H15M9 21V17.625C9 17.004 9.504 16.5 10.125 16.5H13.875C14.496 16.5 15 17.004 15 17.625V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
							<div className="column">
	          		<span className="price-tag">$300 (with Aerial Fottage $350)</span>
	          		<span className="space">3,001 - 6,000 ft2</span>
	          	</div>
          	</div>

          	<div className="line">
          		<div className="icon">
	          		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M2.25 21H21.75M3.75 3V21M14.25 3V21M20.25 7.5V21M6.75 6.75H7.5M6.75 9.75H7.5M6.75 12.75H7.5M10.5 6.75H11.25M10.5 9.75H11.25M10.5 12.75H11.25M6.75 21V17.625C6.75 17.004 7.254 16.5 7.875 16.5H10.125C10.746 16.5 11.25 17.004 11.25 17.625V21M3 3H15M14.25 7.5H21M17.25 11.25H17.258V11.258H17.25V11.25ZM17.25 14.25H17.258V14.258H17.25V14.25ZM17.25 17.25H17.258V17.258H17.25V17.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
							<div className="column">
	          		<span className="price-tag">$350 (with Aerial Footage $400)</span>
	          		<span className="space">6,001 - 8,000 ft2</span>
	          	</div>
          	</div>
          	<div className="free-consultation button">
          		<CustomLink to="/contact">Inquire Now</CustomLink>
        		</div>
          </div> 
    		</div>
    		<img src={videoTours} alt="Video Tours" />
  		</div>

 			<div className="our-mission flipped" ref={animationBox4}>
    		<div className="our-mission-text virtual-staging">
    			<div className="on-sale">ON SALE!</div>
    			<div className="first-line">
	          <h2>Virtual Staging</h2>
	          <span className="spot-light blue">$45 <span>per image</span></span>
	        </div>
          <p>Virtual staging can transform an empty or outdated space into a visually appealing and inviting home. By adding furniture, decor, and even virtual renovations, you can showcase the property's full potential!</p>
          <div className="free-consultation button">
          	<CustomLink to="/contact">Inquire Now</CustomLink>
        	</div>
    		</div>
    		
      	<img src={virtualStaging} alt="Virtual Staging" /> 
  		</div>

  		<div className="our-mission" ref={animationBox5}>
    		<div className="our-mission-text twilight-dusk-images">
          <div className="first-line">
	          <h2>Twilight/Dusk Images</h2>
	          <span className="spot-light blue">$125</span>
	        </div>
          <p>Magic hour is a thing. Twilight Photography brings the "WOW" factor!</p>
          <p>The soft, warm lighting at twilight can highlight a property's exterior features, landscaping, and architectural details, making it more visually appealing to potential buyers and tenants.</p>
    			<div className="price-list">
	    			<div className="line">
	          		<div className="icon">
		          		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 6C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75H20.25C20.8467 3.75 21.419 3.98705 21.841 4.40901C22.2629 4.83097 22.5 5.40326 22.5 6V18C22.5 18.5967 22.2629 19.169 21.841 19.591C21.419 20.0129 20.8467 20.25 20.25 20.25H3.75C3.15326 20.25 2.58097 20.0129 2.15901 19.591C1.73705 19.169 1.5 18.5967 1.5 18V6ZM3 16.06V18C3 18.414 3.336 18.75 3.75 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18C21 16.7579 20.5064 15.5666 19.628 14.6885L18.31 13.371C18.0287 13.0901 17.6475 12.9323 17.25 12.9323C16.8525 12.9323 16.4713 13.0901 16.19 13.371C15.7041 13.8564 15.7038 14.6438 16.1895 15.1295L16.28 15.22C16.3537 15.2887 16.4128 15.3715 16.4538 15.4635C16.4948 15.5555 16.5168 15.6548 16.5186 15.7555C16.5204 15.8562 16.5018 15.9562 16.4641 16.0496C16.4264 16.143 16.3703 16.2278 16.299 16.299C16.2278 16.3703 16.143 16.4264 16.0496 16.4641C15.9562 16.5018 15.8562 16.5204 15.7555 16.5186C15.6548 16.5168 15.5555 16.4948 15.4635 16.4538C15.3715 16.4128 15.2887 16.3537 15.22 16.28L10.06 11.121C9.77875 10.8401 9.3975 10.6823 9 10.6823C8.6025 10.6823 8.22125 10.8401 7.94 11.121L3.00071 16.0603C3.00045 16.0606 3 16.0604 3 16.06ZM13.125 8.25C13.125 7.95163 13.2435 7.66548 13.4545 7.4545C13.6655 7.24353 13.9516 7.125 14.25 7.125C14.5484 7.125 14.8345 7.24353 15.0455 7.4545C15.2565 7.66548 15.375 7.95163 15.375 8.25C15.375 8.54837 15.2565 8.83452 15.0455 9.0455C14.8345 9.25647 14.5484 9.375 14.25 9.375C13.9516 9.375 13.6655 9.25647 13.4545 9.0455C13.2435 8.83452 13.125 8.54837 13.125 8.25Z" fill="url(#paint0_linear_344_582)"/>
										<defs>
											<linearGradient id="paint0_linear_344_582" x1="12" y1="20.25" x2="12" y2="3.75" gradientUnits="userSpaceOnUse">
												<stop stop-color="#0C5FDC"/>
												<stop offset="1" stop-color="#73BAFB"/>
											</linearGradient>
										</defs>
									</svg>
								</div>
								<div className="column">
									<span className="price-tag">8-12 photos</span>
								</div>
          	</div>
          	<div className="free-consultation button">
          		<CustomLink to="/contact">Inquire Now</CustomLink>
        		</div>
    			</div>
    		</div>
    		<img src={duskImages} alt="Twilight/Dusk Images" /> 
  		</div>

 			<div className="our-mission flipped" ref={animationBox6}>
    		<div className="our-mission-text virtual-tour">
    			<span className="spot-light standard">Starting at $145</span>
          <h2>3D matterport virtual tour</h2>
          <p>Using the latest Matterport technology, we create 3D virtual tours and black line floor plans that are the next best thing to being inside a home or at a property. Tours are hosted free for 1 year, then $99 for an additional year.</p>
          <p>$50 per 1,000 Ft2 over 2,000 Ft2</p>
          <div className="price-list combos">
          	<div className="line combo-list">
		    			<div className="combo">
		          		<div className="icon">
			          		<img src={iconDiamond} alt="Icon Diamond" /> 
									</div>
									<div className="column">
										<span className="price-tag">$150</span>
		          			<span className="space">Up To 2,000 ft2</span>
									</div>
	          	</div>
	          	<div className="combo">
		          		<div className="icon">
			          		<img src={iconDiamond} alt="Icon Diamond" /> 
									</div>
									<div className="column">
										<span className="price-tag">$250</span>
		          			<span className="space">3,001 - 4,000 ft2</span>
									</div>
	          	</div>
	          	<div className="combo">
		          		<div className="icon">
			          		<img src={iconDiamond} alt="Icon Diamond" /> 
									</div>
									<div className="column">
										<span className="price-tag">$350</span>
		          			<span className="space">5,001 - 6,000 ft2</span>
									</div>
	          	</div>
	          	<div className="combo">
		          		<div className="icon">
			          		<img src={iconDiamond} alt="Icon Diamond" /> 
									</div>
									<div className="column">
										<span className="price-tag">$450</span>
		          			<span className="space">7,001 - 8,000 ft2</span>
									</div>
	          	</div>
	          	<div className="combo">
		          		<div className="icon">
			          		<img src={iconDiamond} alt="Icon Diamond" /> 
									</div>
									<div className="column">
										<span className="price-tag">$200</span>
		          			<span className="space">2,001 - 3,000 ft2</span>
									</div>
	          	</div>
	          	<div className="combo">
		          		<div className="icon">
			          		<img src={iconDiamond} alt="Icon Diamond" /> 
									</div>
									<div className="column">
										<span className="price-tag">$300</span>
		          			<span className="space">4,001 - 5,000 ft2</span>
									</div>
	          	</div>
	          	<div className="combo">
		          		<div className="icon">
			          		<img src={iconDiamond} alt="Icon Diamond" /> 
									</div>
									<div className="column">
										<span className="price-tag">$400</span>
		          			<span className="space">6,001 - 7,000 ft2</span>
									</div>
	          	</div>
          	</div>
    			</div>
    			<div className="free-consultation button">
          		<CustomLink to="/contact">Inquire Now</CustomLink>
        		</div>
    		</div>
      	<img src={virtualTour} alt="3D matterport virtual tour" /> 
  		</div>

  		<div className="contact-us-box">
  			<div className="info-box">
	        <h3 className="size-s centered">Have a question? Contact us!</h3>
	        {/*<ul className="info-box-bullets">
	          <li>
	            <figure>
	              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M21.75 6.75V17.25C21.75 17.8467 21.5129 18.419 21.091 18.841C20.669 19.2629 20.0967 19.5 19.5 19.5H4.5C3.90326 19.5 3.33097 19.2629 2.90901 18.841C2.48705 18.419 2.25 17.8467 2.25 17.25V6.75M21.75 6.75C21.75 6.15326 21.5129 5.58097 21.091 5.15901C20.669 4.73705 20.0967 4.5 19.5 4.5H4.5C3.90326 4.5 3.33097 4.73705 2.90901 5.15901C2.48705 5.58097 2.25 6.15326 2.25 6.75M21.75 6.75V6.993C21.75 7.37715 21.6517 7.75491 21.4644 8.0903C21.2771 8.42569 21.0071 8.70754 20.68 8.909L13.18 13.524C12.8252 13.7425 12.4167 13.8582 12 13.8582C11.5833 13.8582 11.1748 13.7425 10.82 13.524L3.32 8.91C2.99292 8.70854 2.72287 8.42669 2.53557 8.0913C2.34827 7.75591 2.24996 7.37815 2.25 6.994V6.75" stroke="#FEBA57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
	            </figure>
	            <div>info@1on1Home.com</div>
	          </li>
	          <li>
	            <figure>
	              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M2.25 6.75C2.25 15.034 8.966 21.75 17.25 21.75H19.5C20.0967 21.75 20.669 21.5129 21.091 21.091C21.5129 20.669 21.75 20.0967 21.75 19.5V18.128C21.75 17.612 21.399 17.162 20.898 17.037L16.475 15.931C16.035 15.821 15.573 15.986 15.302 16.348L14.332 17.641C14.05 18.017 13.563 18.183 13.122 18.021C11.4849 17.4191 9.99815 16.4686 8.76478 15.2352C7.53141 14.0018 6.58087 12.5151 5.979 10.878C5.817 10.437 5.983 9.95 6.359 9.668L7.652 8.698C8.015 8.427 8.179 7.964 8.069 7.525L6.963 3.102C6.90214 2.85869 6.76172 2.6427 6.56405 2.48834C6.36638 2.33397 6.1228 2.25008 5.872 2.25H4.5C3.90326 2.25 3.33097 2.48705 2.90901 2.90901C2.48705 3.33097 2.25 3.90326 2.25 4.5V6.75Z" stroke="#FEBA57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
	            </figure>
	            <div>+1 (333) 123 1235</div>
	          </li>
	          <li>
	            <figure>
	              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M15 10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5C9 9.70435 9.31607 8.94129 9.87868 8.37868C10.4413 7.81607 11.2044 7.5 12 7.5C12.7956 7.5 13.5587 7.81607 14.1213 8.37868C14.6839 8.94129 15 9.70435 15 10.5Z" stroke="#FEBA57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M19.5 10.5C19.5 17.642 12 21.75 12 21.75C12 21.75 4.5 17.642 4.5 10.5C4.5 8.51088 5.29018 6.60322 6.6967 5.1967C8.10322 3.79018 10.0109 3 12 3C13.9891 3 15.8968 3.79018 17.3033 5.1967C18.7098 6.60322 19.5 8.51088 19.5 10.5Z" stroke="#FEBA57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
	            </figure>
	            <div>1223 Nice Avenue, Los Angeles, CA 90001</div>
	          </li>
	        </ul>*/}
	        <div className="auth-box">
			      <div className="sub-title">We will respond to your question ASAP</div>
			      <ContactForm />
			    </div>
      	</div>
  		</div>
    </div>
  </>
}
