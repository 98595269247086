import { useEffect, useRef } from 'react'
import SearchBox from './SearchBox'
import { initAnimationObserver } from '../helpers'

export default function CtaBox() {
  const ctaElement = useRef()

  useEffect(() => initAnimationObserver(ctaElement), [ctaElement])

  return <div className="cta-box" ref={ctaElement}>
    <div className="cta-box-inner">
      <h2>Ready to Sell?</h2>
      <div className="cta-box-info">Enter your address to discover how much 1on1 can help you save on your property sale!</div>
      <SearchBox />
    </div>
  </div>
}
