import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import CustomLink from '../components/CustomLink'

export default function Login() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [formErrors, setFormErrors] = useState([])

  return <form className="auth-box" onSubmit={event => {
    event.preventDefault()

    setSubmitted(true)
    setFormErrors([])

    axios.post('/login', {
      email,
      password
    }).then(({ data }) => {
      setSubmitted(false)

      if (data.status === 'error') {
        const errors = data.errors
        const formErrors = []

        for (let i in errors) {
          formErrors.push(errors[i].join('; ').trim())
        }

        setFormErrors(formErrors)
      } else {
        localStorage.setItem('1on1Home_userId', data.data.id)
        navigate(`/dashboard`)
      }
    }).catch(function (error) {
      setSubmitted(false)
      alert(error)
    })
  }}>
    <h3 className="size-s">Log In</h3>
    <div className="formfield">
      <input 
        type="email" 
        value={email}
        placeholder="Email" 
        onChange={({target}) => setEmail(target.value)}
      />
    </div>
    <div className="formfield">
      <input 
        type="password" 
        value={password}
        placeholder="Set Password" 
        onChange={({target}) => setPassword(target.value)}
      />
    </div>
    <div className="forgot">
      <CustomLink to="/auth/password">Forgot Password?</CustomLink>
    </div>

    {
      formErrors.map(formError => <div className="form-error" key={formError}>
        {formError}
      </div>)
    }

    <button className="button full" disabled={submitted}>Log In</button>
    <div className="auth-box-footer">
      Don't have an account? <CustomLink to="/auth/register">Sign Up</CustomLink>
    </div>
  </form>
}
