import { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Streetview from 'react-google-streetview'

import PropertyPrice from './PropertyPrice'
import PropertyLocation from './PropertyLocation'

import { GOOGLE_API_KEY } from '../config'

export default function Property({ property, allowAdminControls=false, hidePrice=false }) {
  const { id } = useParams()
  const payload = JSON.parse(property.payload)
  const { lat, lng } = payload.geometry.location
  const [embedType, setEmbedType] = useState(null)
  const [isOfferFormShowing, setIsOfferFormShowing] = useState(false)
  const [isOfferFormSubmitted, setIsOfferFormSubmitted] = useState(false)
  const [isOfferFormSuccess, setIsOfferFormSuccess] = useState(false)
  const [offerFormError, setOfferFormError] = useState('')
  const [newOffer, setNewOffer] = useState({
    price: 0,
    type: 'cash',
    inspection_period: 'none',
    closing_at: ''
  })

  useEffect(() => {
    axios.get(`https://maps.googleapis.com/maps/api/streetview?size=200x200&location=${lat},${lng}&sensor=false&key=${GOOGLE_API_KEY}`).then(({headers}) => {
      setEmbedType(headers['content-length'] > 5000 ? 'streeview' : 'map')
    })
  }, [lat, lng])

  return <article>
    <figure style={{ backgroundColor: '#eee' }}>
      {
        embedType === 'streeview'
        ? <div style={{ width: '100%', height: '250px' }}>
          <Streetview 
            apiKey={GOOGLE_API_KEY} 
            streetViewPanoramaOptions={{
              position: { lat, lng },
              pov: { heading: 100, pitch: 0 },
              fullscreenControl: false,
              addressControl: false,
              showRoadLabels: false,
              zoomControl: false,
              clickToGo: false,
              disableDefaultUI: true,
              zoom: 1
            }}
          />
        </div>
        : null
      }

      {
        embedType === 'map'
        ? <iframe
          width="100%"
          height="250"
          loading="lazy"
          allowFullScreen
          title="Embedded map"
          style={{ border: 0, display: 'block' }}
          referrerPolicy="no-referrer-when-downgrade"
          src={ `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${lat},${lng}&zoom=18` }>
        </iframe>
        : null
      }
    </figure>

    <div className="listing-data">
      <div className="listing-text">
        <h3>{payload.formatted_address}</h3>
        <PropertyLocation 
          coordinates={`${lat},${lng}`}
        />
      </div>
      {
        hidePrice
        ? null
        : <PropertyPrice />
      }
    </div>

    {
      allowAdminControls
      ? <div className="listing-admin">
        {
          isOfferFormShowing
          ? <div className="auth-box compacted">
            <div className="formfield">
              <label>Upload PDF</label>
              <input type="file" accept="application/pdf" />
            </div>
            <div className="formfield">
              <label>Offer Price</label>
              <input 
                className="white-bkg" 
                type="text" 
                value={newOffer.price}
                onChange={({target}) => setNewOffer(Object.assign({}, newOffer, { price: target.value }))}
              />
            </div>
            <div className="formfield">
              <label>Cash or Financing</label>
              <select 
                className="white-bkg"
                value={newOffer.type}
                onChange={({target}) => setNewOffer(Object.assign({}, newOffer, { type: target.value }))}
              >
                <option value="cash">Cash</option>
                <option value="financing">Financing</option>
              </select>
            </div>
            <div className="formfield">
              <label>Inspection Period</label>
              <input 
                className="white-bkg" 
                type="text" 
                value={newOffer.inspection_period}
                onChange={({target}) => setNewOffer(Object.assign({}, newOffer, { inspection_period: target.value }))}
              />
            </div>
            <div className="formfield">
              <label>Closing Date</label>
              <input 
                className="white-bkg" 
                type="date" 
                value={newOffer.closing_at}
                onChange={({target}) => setNewOffer(Object.assign({}, newOffer, { closing_at: target.value }))}
              />
            </div>
            {
              offerFormError
              ? <div className="admin-form-error">{offerFormError}</div>
              : null
            }
            {
              isOfferFormSuccess
              ? <div className="admin-form-success">The offer was successfully submitted</div>
              : null
            }
            <div className="listing-admin-submit">
              <button className="button gray no-shadow" onClick={() => setIsOfferFormShowing(false)}>Cancel</button>
              {
                isOfferFormSubmitted
                ? <em>Please wait...</em>
                : <button className="button no-shadow" onClick={({target}) => {
                  setOfferFormError('')

                  const pdfInput = document.querySelector('input[type=file]')

                  if (!pdfInput.value) {
                    setOfferFormError('Please select the PDF file')
                  } else {
                    setIsOfferFormSubmitted(true)

                    const formData = new FormData()
                    formData.append('session_id', `1on1_offer_${id}`);
                    formData.append('file', pdfInput.files[0])

                    axios({
                      method: 'post',
                      url: '/upload',
                      data: formData,
                      headers: { 'Content-Type': 'multipart/form-data' },
                    }).then(({data}) => {
                      setIsOfferFormSubmitted(false)
                      console.log('response', data.data)

                      axios.post('/offers', {
                        file: data.data,
                        user_id: id,
                        property_id: 0,
                        price: newOffer.price,
                        type: newOffer.type,
                        inspection_period: newOffer.inspection_period,
                        closing_at: newOffer.closing_at
                      }).then(({data}) => {
                        setIsOfferFormSubmitted(false)

                        if (data.status === 'error') {
                          setOfferFormError(Object.values(data.errors).map(error => error.join(' ')).join(' '))
                        } else {
                          setIsOfferFormSuccess(true)

                          setTimeout(() => {
                            setIsOfferFormSuccess(false)
                            setIsOfferFormShowing(false)
                            window.location.reload()
                          }, 2500)
                        }
                      }).catch(error => {
                        setIsOfferFormSubmitted(false)
                        setOfferFormError(error.message || JSON.stringify(error))
                      })

                    }).catch(error => {
                      setIsOfferFormSubmitted(false)
                      setOfferFormError(error.message || JSON.stringify(error))
                    })
                  }
                }}>
                  Submit
                </button>
              }
            </div>
          </div>
          : <button className="button no-shadow" onClick={() => setIsOfferFormShowing(true)}>Upload Offer</button>
        }
      </div>
      : null
    }
  </article>
}
