import { useState, useEffect } from 'react'
import axios from 'axios'
import OffersListing from '../components/OffersListing'

export default function Offers() {
	const userId = localStorage.getItem('1on1Home_userId')
	const [entries, setEntries] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		axios.get(`/offers?user_id=${userId}`).then(({data}) => {
			setEntries(data.data)
			setLoading(false)
		}).catch(() => setLoading(false))
	}, [userId])

	return <>
		<h2 className="admin-page-title">Offers</h2>
		<OffersListing 
			entries={entries}
			loading={loading}
		/>
	</>
}
