import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Autocomplete from 'react-google-autocomplete'
import PhoneField from './PhoneField'
import { GOOGLE_API_KEY } from '../config'

import { Magnifier as MagnifierIcon } from '../icons'

function formatMoney(value) {
  return `$${(value * 0.04).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function displaySavings(value) {
  if (value === 100000) {
    return `${formatMoney(value)} - ${formatMoney(250000)}`
  } else if (value === 250000) {
    return `${formatMoney(value)} - ${formatMoney(500000)}`
  } else if (value === 500000) {
    return `${formatMoney(value)} - ${formatMoney(1000000)}`
  }

  return formatMoney(value)
}

export default function SearchBox() {
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [address, setAddress] = useState('')
  const [googleData, setGoogleData] = useState('')
  const [value, setValue] = useState(0)
  const [timeline, setTimeline] = useState('')
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const formId = Math.floor(Math.random() * 10000)

  return <div className="search-box">
    {
      step === 1
      ? <div className="search-box-form">
        <div className="search-box-icon">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5895 19.998C14.9861 17.8888 19.25 13.5795 19.25 9.625C19.25 7.43696 18.3808 5.33854 16.8336 3.79137C15.2865 2.24419 13.188 1.375 11 1.375C8.81196 1.375 6.71354 2.24419 5.16637 3.79137C3.61919 5.33854 2.75 7.43696 2.75 9.625C2.75 13.5795 7.0125 17.8888 9.4105 19.998C9.84791 20.3884 10.4137 20.6042 11 20.6042C11.5863 20.6042 12.1521 20.3884 12.5895 19.998ZM8.25 9.625C8.25 8.89566 8.53973 8.19618 9.05546 7.68046C9.57118 7.16473 10.2707 6.875 11 6.875C11.7293 6.875 12.4288 7.16473 12.9445 7.68046C13.4603 8.19618 13.75 8.89566 13.75 9.625C13.75 10.3543 13.4603 11.0538 12.9445 11.5695C12.4288 12.0853 11.7293 12.375 11 12.375C10.2707 12.375 9.57118 12.0853 9.05546 11.5695C8.53973 11.0538 8.25 10.3543 8.25 9.625Z" fill="#FEBA57"/>
          </svg>
        </div>
        <fieldset>
          <label 
            className="search-box-label"
            htmlFor={`search-box-input-${formId}`}
          >Locations</label>
          <Autocomplete
            placeholder="Search by county, city or ZIP"
            id={`search-box-input-${formId}`}
            className="search-box-input"
            apiKey={GOOGLE_API_KEY}
            onPlaceSelected={place => {
              setAddress(place ? place.formatted_address : '')
              setGoogleData(JSON.stringify(place))
              setStep(2)
            }}
            options={{
              types: ['geocode'],
              componentRestrictions: { country: 'us' }
            }}
          />
        </fieldset>
        <button type="button" className="button bullet">
          <MagnifierIcon />
        </button>
      </div>
      : null
    }

    {
      step === 2
      ? <div className="auth-box bkg-white w-auto m-t-zero search-filters">
        <h2 className="size-s">{address}</h2>
        {
          [
            {
              title: 'How soon are you looking to sell?',
              options: [
                {
                  value: 'right-away',
                  text: 'Right Away'
                },
                {
                  value: '1-3-months',
                  text: '1-3 Months'
                },
                {
                  value: '4plus-months',
                  text: '4+ Months'
                },
                {
                  value: 'already-listed',
                  text: 'Already Listed'
                }
              ]
            }
          ].map(filter => <div className="filter-group" key={filter.title}>
            <h3>{filter.title}</h3>
            <ul className="two-cols">
              {
                filter.options.map(option => <li key={option.value}>
                  <label>
                    <input 
                      type="checkbox" 
                      checked={value === option.value}
                      onChange={() => {
                        setTimeline(option.value)
                        setStep(3)
                      }}
                    />
                    {option.text}
                  </label>
                </li>)
              }
            </ul>
          </div>)
        }
      </div>
      : null
    }

    {
      step === 3
      ? <div className="auth-box bkg-white w-auto m-t-zero search-filters">
        <h2 className="size-s">{address}</h2>
        {
          [
            {
              title: 'How much do you think your home is worth?',
              options: [
                {
                  value: 50000,
                  text: 'less than 100k', 
                },
                {
                  value: 100000,
                  text: '100-250k', 
                },
                {
                  value: 250000,
                  text: '250-500k', 
                },
                {
                  value: 500000,
                  text: '500k-1 Million',
                },
                {
                  value: 1000000,
                  text: 'More than a Million',
                },
                {
                  value: -1,
                  text: 'You tell me'
                }
              ]
            }
          ].map(filter => <div className="filter-group" key={filter.title}>
            <h3>{filter.title}</h3>
            <ul className="two-cols">
              {
                filter.options.map(option => <li key={option.value}>
                  <label>
                    <input 
                      type="checkbox" 
                      checked={value === option.value}
                      onChange={() => {
                        setValue(option.value)
                        setStep(4)
                      }}
                    />
                    {option.text}
                  </label>
                </li>)
              }
            </ul>
          </div>)
        }
      </div>
      : null
    }

    {
      step === 4
      ? <div className="auth-box bkg-white w-auto m-t-zero search-filters">
        <h2 className="size-s">{address}</h2>
        {
          [
            {
              title: 'What kind of home are you looking to sell?',
              options: [
                {
                  value: 'single',
                  text: 'Single Family', 
                },
                {
                  value: 'condominium',
                  text: 'Condominium', 
                },
                {
                  value: 'townhouse',
                  text: 'Townhouse', 
                },
                {
                  value: 'multi-family',
                  text: 'Multi-Family',
                },
                {
                  value: 'other',
                  text: 'Vacant Lot/Other',
                },
                {
                  value: 'commercial',
                  text: 'Commercial'
                }
              ]
            }
          ].map(filter => <div className="filter-group" key={filter.title}>
            <h3>{filter.title}</h3>
            <ul className="two-cols">
              {
                filter.options.map(option => <li key={option.value}>
                  <label>
                    <input 
                      type="checkbox" 
                      checked={value === option.value}
                      onChange={() => {
                        setType(option.value)
                        setStep(5)
                      }}
                    />
                    {option.text}
                  </label>
                </li>)
              }
            </ul>
          </div>)
        }
      </div>
      : null
    }

    {
      step === 5
      ? <div className="auth-box bkg-white w-auto m-t-zero search-filters">
        <h2 className="size-s">
          The potential savings you can expect when selling your home with 1on1Home are estimated to be:
          {
            value === -1
            ? ' will be calculated by our agents later'
            : <span className="red-text text-l"> {displaySavings(value)}</span>
          }
        </h2>
        <form autocomplete="off" onSubmit={event => {
          event.preventDefault()
          navigate(`/auth/register?name=${name}&phone=${phone}&email=${email}`)
          localStorage.setItem('1on1Home_googleData', googleData)
          window.scrollTo(0,0)
        }}>
          <div className="filter-group">
            <h3>Your Information</h3>
            <ul>
              <li>
                <input 
                  type="text" 
                  value={name}
                  placeholder="Name" 
                  onChange={({target}) => setName(target.value)}
                />
              </li>
              <li>
                <PhoneField 
                  placeholder="Phone" 
                  name="skip" 
                  value={phone} 
                  onChange={number => setPhone(number)} 
                />
              </li>
              <li>
                <input 
                  type="email" 
                  value={email}
                  placeholder="Email" 
                  onChange={({target}) => setEmail(target.value)}
                />
              </li>
            </ul>
          </div>
          <button type="submit" className="button full">
            Register Now
          </button>
        </form>
      </div>
      : null
    }
  </div>
}
