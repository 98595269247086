import CustomLink from '../components/CustomLink'
import ContactForm from '../components/ContactForm'

export default function Brokerage() {
  return <>
  	<div className="page-title services">
      <h2>Brokerage</h2>
    </div>

    <div className="site-content services">
 			<div className="our-mission is-animated">
    		<div className="our-mission-text single-item">
          <p>1on1Home has revolutionized the real estate brokerage industry by offering an innovative pricing model that significantly lowers the cost of selling and buying homes. Traditionally, real estate transactions involve higher brokerage fees, which can substantially eat into the seller's profits and increase the buyer's costs. By charging only 1% brokerage services on both the seller and buyer sides, 1on1Home delivers remarkable savings to its clients. For the average home seller, this approach translates into savings of around $30,000, a substantial amount that underscores the financial advantages of choosing 1on1Home for real estate transactions.</p>
					<p>Despite these lower fees, 1on1Home does not compromise on the quality or range of services provided. The company offers full-service brokerage services, ensuring that every aspect of the selling or buying process is handled with professionalism and expertise. This comprehensive service includes everything from listing the property and marketing it to potential buyers, to negotiating deals and handling the paperwork. 1on1Home's approach ensures that clients receive the same level of service they would expect from a traditional brokerage, but at a fraction of the cost.</p>
					<p>Moreover, 1on1Home offers a unique, customizable experience for home sellers. Recognizing that each client has different needs and preferences, 1on1Home allows sellers to tailor the brokerage services according to their specific requirements. Whether a seller wants to be more hands-on in certain aspects of the sale or prefers to leave everything to the professionals, 1on1Home accommodates these preferences, providing a personalized real estate experience that caters to individual needs.</p>
					<p>At the heart of 1on1Home's service is the assurance of having a licensed real estate broker dedicated to protecting the client's most valuable investment. This ensures that, in addition to saving money, clients also have the peace of mind knowing that their transaction is being managed by a knowledgeable and experienced professional. The combination of significant cost savings, full-service brokerage, customizable experiences, and expert guidance makes 1on1Home an attractive option for anyone looking to sell or buy a home efficiently and affordably.</p>
          <div className="price-list">
          	<div className="free-consultation button">
          		<CustomLink to="/contact">Full Service Brokerage 1%</CustomLink>
        		</div>
          </div>
    		</div>
  		</div>

  		<div className="contact-us-box">
  			<div className="info-box">
	        <h3 className="size-s centered">Have a question? Contact us!</h3>
	        <div className="auth-box">
			      <div className="sub-title">We will respond to your question ASAP</div>
			      <ContactForm />
			    </div>
      	</div>
  		</div>
    </div>
  </>
}
