import CustomLink from './CustomLink'
import moment from 'moment'

export default function Article({ article=null }) {
  if (!article) {
    return null
  }

  return <CustomLink className="article" to={`/article/${article.id}`}>
    {
      article.image
      ? <figure>
        <img src={`https://locatorfee.com${article.image.variants.large}`} alt="Article media" />
      </figure>
      : null
    }
    <div className="listing-data">
      <div className="listing-text">
        <div className="article-date">{moment(article.created_at).format('Do MMMM y').toLowerCase()}</div>
        <h3>{article.title}</h3>
        <div>{article.content.replace(/(<([^>]+)>)/gi, '').substring(0, 180).trim()}...</div>
        <span className="button gray">Read Article</span>
      </div>
    </div>
  </CustomLink>
}
