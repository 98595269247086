export default function PropertyLocation({ 
  text='Location',
  coordinates=''
}) {
  return <span className="property-location">
    <div className="search-box-icon">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5895 19.998C14.9861 17.8888 19.25 13.5795 19.25 9.625C19.25 7.43696 18.3808 5.33854 16.8336 3.79137C15.2865 2.24419 13.188 1.375 11 1.375C8.81196 1.375 6.71354 2.24419 5.16637 3.79137C3.61919 5.33854 2.75 7.43696 2.75 9.625C2.75 13.5795 7.0125 17.8888 9.4105 19.998C9.84791 20.3884 10.4137 20.6042 11 20.6042C11.5863 20.6042 12.1521 20.3884 12.5895 19.998ZM8.25 9.625C8.25 8.89566 8.53973 8.19618 9.05546 7.68046C9.57118 7.16473 10.2707 6.875 11 6.875C11.7293 6.875 12.4288 7.16473 12.9445 7.68046C13.4603 8.19618 13.75 8.89566 13.75 9.625C13.75 10.3543 13.4603 11.0538 12.9445 11.5695C12.4288 12.0853 11.7293 12.375 11 12.375C10.2707 12.375 9.57118 12.0853 9.05546 11.5695C8.53973 11.0538 8.25 10.3543 8.25 9.625Z" fill="#FEBA57"/>
      </svg>
    </div>
    <a 
      href={`https://www.google.com/maps/search/?api=1&query=${coordinates}`} 
      rel="noreferrer"
      target="_blank"
      >
        {text} (<span>view on map</span>)
    </a>
  </span>
}
