import { useState, useEffect } from 'react'
import axios from 'axios'
import useUserPermissions from '../hooks/useUserPermissions'
import { Trash as TrashIcon } from '../icons'

export default function Listings() {
	const [search, setSearch] = useState('')
	const [listings, setListings] = useState([])
	const [matches, setMatches] = useState([])
	const [loading, setLoading] = useState(true)
	const [state, setState] = useState(1)
	const { isAdmin } = useUserPermissions()

	useEffect(() => {
		axios.get('/properties/rets/imports').then(({data}) => {
			setListings(data.data)
			setLoading(false)
		})
	}, [state])

	if (!isAdmin) {
		return null
	}

  return <>
  	<div className="admin-page-head">
	  	<h2 className="admin-page-title">Listings</h2>
	  	<div className="admin-top-search">
	  		<input 
	  			type="text" 
	  			placeholder="MLS ID" 
	  			value={search} 
	  			onChange={(event) => setSearch(event.target.value)} 
  			/>
	  		<span 
	  			className="button"
	  			onClick={() => {
	  				setLoading(true)
	  				axios.get(`/properties/rets/search?search=${search.trim()}`).then(({data}) => {
	  					setMatches(data.data)
	  					setLoading(false)
	  				})
	  			}}
  			>
	  			Search
  			</span>

	  		{
	  			loading || matches.length
	  			? <div className="top-search-results">
	  				{
	  					loading
	  					? <div>
	  						<em>Please wait...</em>
  						</div>
	  					: <>
	  						{
	  							matches.map(match => <div key={match.ListingId} className="top-search-result" onClick={() => {
	  								setSearch('')
	  								setMatches([])
	  								setLoading(true)

	  								axios.get(`/properties/rets/import?search=${match.ListingId}`).then(({data}) => {
											setState(state * 2)
										})
	  							}}>
	  								{match.StreetNumber} {match.StreetName} {match.City}
	  								<span className="text-button">Save</span>
  								</div>)
	  						}
	  					</>
	  				}
  				</div>
	  			: null
	  		}
	  	</div>
  	</div>
  	<div className="scrollable-holder">
			<table className="scrollable">
				<thead>
					<tr>
		  			<th>MLS ID</th>
		  			<th>Address</th>
		  			<th>Price</th>
		  			<th>Type</th>
		  			<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{
						listings.map(listing => <tr key={listing.id}>
				      <td>{listing.data.ListingId}</td>
				      <td>{listing.data.StreetNumber} {listing.data.StreetName} {listing.data.City}</td>
				      <td>${listing.data.OriginalListPrice}</td>
				      <td>{listing.data.PropertyType}</td>
				      <td className="action-buttons">
				      	<span className="random-button" onClick={() => {
				      		if (window.confirm('Are you sure?')) {
				      			axios.post(`/properties/rets/delete/${listing.id}`).then(() => {
				      				setState(state * 2)
				      			})
				      		}
				      	}}>
				      		<TrashIcon />
			      		</span>
				      </td>
			      </tr>)
					}

					{
						!listings.length && !loading
						? <tr>
							<td colSpan="4">
								You have no listings at the moment...
							</td>
						</tr>
						: null
					}
		    </tbody>
	    </table>
    </div>
  </>
}
