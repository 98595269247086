export default function Privacy() {
  return <>
    <div className="page-title">
      <h2>Privacy policy</h2>
    </div>
    <div className="site-content static-page">
        <h3>Lorem Ipsum Dolor sit</h3>
        <p>
          Created by: Rich McIver
          <br/>
          Last updated on 1/12/2021
        </p>

        <h4>1. Lorem Ipsum dolor sit amet</h4>
        <p>Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.</p>
        <p>Sodales ornare non ut risus mollis aliquam eget tempor. Elit sit sed senectus penatibus ridiculus pellentesque feugiat nulla tortor. Volutpat ut cras in non sagittis egestas proin. Tellus viverra bibendum quis velit ultricies in dictum.</p>
        <p>Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.</p>
        <p>Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.</p>
        
        <h4>2. Lorem Ipsum dolor sit amet</h4>
        <p>Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.</p>
        <p>Sodales ornare non ut risus mollis aliquam eget tempor. Elit sit sed senectus penatibus ridiculus pellentesque feugiat nulla tortor. Volutpat ut cras in non sagittis egestas proin. Tellus viverra bibendum quis velit ultricies in dictum.</p>
        <p>Lorem ipsum dolor sit amet consectetur. Id adipiscing sit imperdiet massa est sagittis quis in. Maecenas libero auctor volutpat vehicula nec cum quis. Dapibus ut sed ullamcorper in. Sem leo bibendum leo vitae eget mollis ullamcorper sodales morbi. Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.</p>
        <p>Aenean quam auctor fermentum amet ultricies pretium nisl pharetra. Nec morbi non eget dignissim pellentesque augue massa est. Aliquet scelerisque vulputate amet tincidunt malesuada libero sed a odio. Tortor volutpat euismod nibh sodales accumsan at cursus. Libero semper tellus vitae varius odio ipsum quis nunc. Etiam tempor tempor tincidunt metus volutpat pulvinar.</p>
    </div>
  </>
}
