import moment from 'moment'
import { formatPrice, capitalizeFirstLetter } from '../helpers'

export default function OffersListing({ entries=[], loading=false }) {
	return <div className="scrollable-holder">
		<table className="scrollable">
			<thead>
				<tr>
	  			<th>Contract (PDF)</th>
	  			<th>Offer Price</th>
	  			<th>Cash/Financing</th>
	  			<th>Inspection Period</th>
	  			<th>Closing Date</th>
				</tr>
			</thead>
			<tbody>
				{
					entries.map(entry => <tr key={entry.id}>
			      <td>
			      	<a className="button" href={entry.file_url} target="_blank" rel="noreferrer">View Contract</a>
		      	</td>
			      <td>${formatPrice(entry.price)}</td>
			      <td>{capitalizeFirstLetter(entry.type)}</td>
			      <td>{entry.inspection_period}</td>
			      <td>{moment(entry.closing_at).format('MM/DD/y')}</td>
		      </tr>)
				}

				{
					!loading && !entries.length
					? <tr>
						<td colSpan="7">
							No offers at the moment...
						</td>
					</tr>
					: null
				}
	    </tbody>
    </table>
  </div>
}
