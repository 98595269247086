import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Property from './Property'
import ContactForm from './ContactForm'
import PropertyRets from './PropertyRets'
import PropertyPlaceholder from './PropertyPlaceholder'
import CustomLink from './CustomLink'
// import { initAnimationObserver } from '../helpers'

export default function Properties({ 
    title='Similar Properties', 
    allowAdminControls=false,
    showContactForm=false,
    hideHeadBar=false, 
    properties=[1,2,3],
    hidePrice=false,
    realData=false,
    retsData=false,
    className='',
    exclude=null,
    max=0
  }) {
  const listingElement = useRef()
  const [listings, setListings] = useState([])

  // useEffect(() => initAnimationObserver(listingElement), [listingElement])
  // animated-listing ${className}

  useEffect(() => {
    if (retsData) {
      axios.get('/properties/rets/imports').then(({data}) => {
        let properties = data.data

        if (exclude) {
          properties = properties.filter(({ mls_id }) => mls_id !== exclude)
        }

        if (max) {
          properties = properties.slice(0, max)
        }

        setListings(properties)
      })
    }
  }, [retsData])

  return <div className={`listing `} ref={listingElement}>
    {
      hideHeadBar
      ? null
      : <div className="head-bar">
        <h2>{title}</h2>
        <CustomLink to="/search" className="button bordered">See More</CustomLink>
      </div>
    }
    <div className="listing-articles">
      {
        realData
        ? properties.map(property => <Property key={property.id} property={property} allowAdminControls={allowAdminControls} hidePrice={hidePrice} />)
        : (
          retsData
          ? listings.map(property => <PropertyRets key={property.id} data={property.data} />)
          : properties.map(id => { return { id } }).map(property => <PropertyPlaceholder key={property.id} hidePrice={hidePrice} />)
        )
      }
      {
        showContactForm
        ? <>
          <div className="auth-box for-dashboard">
            <ContactForm />
          </div>
          <div></div>
        </>
        : null
      }
    </div>
  </div>
}
