import { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import Article from '../components/Article'
import CtaBox from '../components/CtaBox'
import CustomLink from '../components/CustomLink'

export default function Blog() {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    axios.get(`/posts`).then(({data}) => setPosts(data.data))
  }, [])

  return <div className="site-content">
    {
      posts.length
      ? <article className="main-article">
        <figure>
          <img src={`https://locatorfee.com${posts[0].image.variants.large}`} alt="Article media" />
        </figure>
        <div className="article-data">
          <div className="article-text">
            <div className="article-date">{moment(posts[0].created_at).format('Do MMMM y').toLowerCase()}</div>
            <h2>{posts[0].title}</h2>
            <div>{posts[0].content.substring(0, 180).trim()}...</div>
            <CustomLink to={`/article/${posts[0].id}`} className="button bordered">Read Article</CustomLink>
          </div>
        </div>
      </article>
      : null
    }
    <div className="listing blog-listing">
      <div className="head-bar">
        <h2>Latest Articles</h2>
      </div>
      <div className="listing-articles">
        {
          posts.map(post => <Article key={post.id} article={post} />)
        }
        <article></article>
        {/*<button className="button gray full load-more">Load More</button>*/}
      </div>
    </div>
    <CtaBox />
  </div>
}
