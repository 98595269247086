import { useEffect, useRef } from 'react'
import CustomLink from './CustomLink'
import { initAnimationObserver } from '../helpers'
import ourMissionImage from '../img/our-mission.jpg'
import gifImage from '../img/gif-animation.gif'

export default function TextAndImageSection({ 
  showMoreButton=false, 
  additionalClass='',
  showVideo=false,
  image=null,
  text=null
}) {
  const sectionElement = useRef()

  useEffect(() => initAnimationObserver(sectionElement, () => {
    const video = sectionElement && sectionElement.current ? sectionElement.current.querySelector('video') : null
    if (video) {
      setTimeout(() => video.play(), 1000)
    }
  }), [sectionElement])

  return <div className={`our-mission ${additionalClass}`} ref={sectionElement}>
    <div className="our-mission-text">
      {
        text || <>
          <h2>Our Mission</h2>
          <p>At 1on1 Home, our mission is to revolutionize the home-selling experience in South Florida. We empower home sellers by providing full-service brokerage expertise while simultaneously saving them thousands of dollars in closing costs. We believe that selling your home should be a seamless and cost-effective process, and we're committed to delivering exceptional service, transparency, and significant financial benefits to our valued clients.</p>
          <p>Our dedicated team is here to guide you every step of the way, ensuring that you maximize your return on investment while experiencing the utmost convenience and support in your real estate journey.</p>
          {
            showMoreButton
            ? <CustomLink className="button" to="/about">Learn More</CustomLink>
            : null
          }
        </>
      }
    </div>
    { 
      image 
      || 
      <>
        {
          showVideo
          /*<video muted playsInline>
            <source src="https://1on1home.com/new_1on1home_video.mp4" type="video/mp4" />
          </video>*/
          ? <img src={gifImage} alt="Our mission media" className="gif-image" />
          : <img src={ourMissionImage} alt="Our mission media" /> 
        }
      </>
    }
  </div>
}
