import CustomLink from '../components/CustomLink'

export default function Password() {
  return <form className="auth-box">
    <h3 className="size-s">Forgot Password</h3>
    <div className="formfield">
      <input type="email" placeholder="Email" />
    </div>
    <button className="button full">Submit</button>
    <div className="auth-box-footer">
      Back to <CustomLink to="/auth/login">Log In</CustomLink>
    </div>
  </form>
}
