import { useState, useEffect } from 'react'

export default function useUserPermissions() {
  const [isAdmin, setIsAdmin] = useState(false)
  const userId = localStorage.getItem('1on1Home_userId')

  useEffect(() => {
  	if (userId) {
  		setIsAdmin([93, 90].indexOf(parseInt(userId)) !== -1)
  	}
  }, [])

  return {
    isAdmin 
  }
}