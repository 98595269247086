import { useEffect, useRef } from 'react'
import { initAnimationObserver } from '../helpers'

import chart1 from '../img/chart1.png'
import chart2 from '../img/chart2.png'
import chart3 from '../img/chart3.png'
import chart4 from '../img/chart4.png'

export default function TrendsBox() {
  const trendsElement = useRef()

  useEffect(() => initAnimationObserver(trendsElement), [trendsElement])

	return <div className="trends-box" ref={trendsElement}>
    <h2>Market Trends in South Florida</h2>
    <div className="trends-box-items">
      <div className="items-row">
        <div className="trends-box-item">
          <figure>
            <img src={chart1} alt="Home Sales Boom" />
          </figure>
          <h3>
            Home<br/>Sales Boom
          </h3>
          <div>
            The upward trajectory of luxury home sales, especially in the $1 million+ price bracket, is anticipated to persist, fueled by an influx of buyers from high-cost regions like California and New York who are relocating their businesses and families to South Florida. This trend is expected to endure in the foreseeable future.
          </div>
        </div>

        <div className="trends-box-item">
          <figure>
            <img src={chart2} alt="Mortgage Rates" />
          </figure>
          <h3>
            Mortgage<br/>Rates
          </h3>
          <div>
            Despite the impact of rising mortgage rates on the South Florida housing market, experts anticipate that these rates will stabilize and perhaps even decline by year-end. This expected stabilization could provide relief for buyers and potentially breathe new life into the housing market. Keeping a close eye on mortgage rate trends and their impact on buyer behavior will be essential for understanding where the market is headed.
          </div>
        </div>
      </div>
      <div className="items-row">
        <div className="trends-box-item">
          <figure>
            <img src={chart3} alt="Inventory Challenges" />
          </figure>
          <h3>
            Inventory<br/>Challenges
          </h3>
          <div>
            The persistent shortage of homes in South Florida, especially in Palm Beach County, remains a significant challenge in the foreseeable future. With low inventory and strong demand, home prices are likely to continue rising. To maintain market growth and achieve a balanced housing market, addressing this inventory shortage is of paramount importance.
          </div>
        </div>

        <div className="trends-box-item">
          <figure>
            <img src={chart4} alt="Adjustments and Competition" />
          </figure>
          <h3>
            Adjustments and Competition
          </h3>
          <div>
            Buyers adapt faster to rising mortgage rates, driving ongoing competition and robust demand, keeping property prices strong, with most selling within a month.
          </div>
        </div>
      </div>
    </div>
  </div>
}
