import { useState } from 'react'
import Slider from 'react-rangeslider'
import { formatPrice } from '../helpers'
import 'react-rangeslider/lib/index.css'

export default function SavingsSimulator() {
	const [simulatorValue, setSimulatorValue] = useState(450000)

	return <div className="site-content">
    <div className="simulator">
      <h2>Calculate how much we can save you on your property sale</h2>

      <div className="simulator-inner">
        <div className="simulator-slider">
          <div className="simulator-selection">${formatPrice(simulatorValue)}</div>
          <div className="simulator-info">Your purchase price</div>
          <Slider
            min={50000}
            max={5000000}
            step={50000}
            tooltip={false}
            value={simulatorValue}
            onChange={value => setSimulatorValue(value)}
          />
          <div className="simulator-cta">Slide to calculate your savings*</div>
        </div>
        <div className="simulator-charts">
          <div className="simulator-chart">
            <div className="simulator-value">${formatPrice(simulatorValue * 0.04)}</div>
            <div className="simulator-label">1on1 Home Savings</div>
            <div className="simulator-chart-bar dynamic" style={{ height: (simulatorValue - 50000) / (5000000 - 50000) * 120 + 40 }}></div>
          </div>
          <div className="simulator-chart">
            <div className="simulator-value">$0</div>
            <div className="simulator-label">Traditional</div>
            <div className="simulator-chart-bar"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="customer-contact-button">
      <p>Get your free consultation right away</p>
      <a className="free-consultation button" href="https://calendly.com/andy-yw0/30min" target="_blank" rel="noreferrer">Free Consultation</a>
    </div>
  </div>
}
