import { useState, useEffect } from 'react'
import axios from 'axios'
import CustomLink from '../components/CustomLink'
import useUserPermissions from '../hooks/useUserPermissions'
import { Trash as TrashIcon } from '../icons'

export default function Users() {
	const [clients, setClients] = useState([])
	const [loading, setLoading] = useState(true)
	const [state, setState] = useState(1)
	const { isAdmin } = useUserPermissions()

	useEffect(() => {
		axios.get('/users?type=1on1user&orderBy=id,desc').then(({data}) => {
			setClients(data.data)
			setLoading(false)
		})
	}, [state])

	if (!isAdmin) {
		return null
	}

  return <>
  	<div className="admin-page-head">
	  	<h2 className="admin-page-title">Clients</h2>
  	</div>
  	<div className="scrollable-holder">
			<table className="scrollable">
				<thead>
					<tr>
		  			<th>Name</th>
		  			<th>Email</th>
		  			<th>Phone</th>
		  			<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{
						clients.map(client => <tr key={client.id}>
				      <td>
				      	{client.name}
				      	&nbsp; &nbsp;
				      	<CustomLink className="text-button" to={`/dashboard/users/${client.id}`}>View Details</CustomLink>
				      </td>
				      <td>{client.email}</td>
				      <td>{client.phone}</td>
				      <td className="action-buttons">
				      	<span className="random-button" onClick={() => {
				      		if (window.confirm('Are you sure?')) {
				      			axios.post(`/users/delete/${client.id}`).then(() => {
				      				setState(state * 2)
				      			})
				      		}
				      	}}>
				      		<TrashIcon />
			      		</span>
				      </td>
			      </tr>)
					}

					{
						!clients.length && !loading
						? <tr>
							<td colSpan="4">
								You have no clients at the moment...
							</td>
						</tr>
						: null
					}
		    </tbody>
	    </table>
    </div>
  </>
}
