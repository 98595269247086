import { useEffect, useRef } from 'react'
import Reviews from '../components/Reviews'
import SearchBox from '../components/SearchBox'
import CustomLink from '../components/CustomLink'
import SavingsSimulator from '../components/SavingsSimulator'
import { initAnimationObserver } from '../helpers'

import googleReviews from '../img/google-reviews.png'
import iconHow1 from '../img/icon-how1.png'
import iconHow2 from '../img/icon-how2.png'
import iconHow3 from '../img/icon-how3.png'
import iconHow4 from '../img/icon-how4.png'

import 'react-rangeslider/lib/index.css'

export default function New() {
  const benefitsElement = useRef()

  useEffect(() => initAnimationObserver(benefitsElement), [benefitsElement])

  return <>
    <div className="home-hero new-hero">
      <div className="home-hero-bkg1">
        <div className="home-hero-inner">
          <div className="new-hero-note">
            Selling your home? Don’t miss this Free Report Packed with closing cost-cutting strategies
          </div>
          <h2>The 5 Hidden Secrets For Selling Your Home FAST, At Top Dollar, While <em>Saving $15,000 Average</em></h2>
          <div className="new-hero-note">For the first time ever, 1 On 1 Home Presents…</div>
          <div className="search-form">
            <div className="new-hero-note">
              Fill out the form below to get your EXACT savings and FREE Report Now!
            </div>
          </div>
          <div className="contact-expert new">
            <div className="new-hero-note">
              Don’t miss out on this FREE REPORT! 1 On 1 Home shares our secret formula for selling your home FAST for top dollar – and saving an average of $15,000 in fees and commission. Tell us where to send it!
            </div>
            <CustomLink to="/contact" className="button shaking-btn">Send Me My Free Report Now</CustomLink>
          </div>
          <div className="new-hero-note short">
            *Our average client saves $15,000 when selling their home. Data compiled from over 800 deals.
          </div>
          <div className="google-reviews">
            <img src={googleReviews} alt="Google Reviews - 5 Stars" />
          </div>
        </div>
        <video loop autoPlay muted playsInline>
          <source src="https://1on1home.com/andy_vid_720p.mp4" type="video/mp4" />
        </video>
        <div className="video-mask"></div>
      </div>
    </div>

    <Reviews />

    <div className="site-content">
      <div className="heart-note">
        <h2>Hey there South Florida Homeowner,</h2>
        <div className="letter">
          <p>Chances are you have been given advice or read about what you should (and shouldn’t) do to sell your house for top dollar or sell your home fast or save thousands on seller’s fees.</p>
          <p>The problem is, “maximum ROI,” “a fast sale,” and “saving costs” don’t often go together. In fact, many realtors will tell you that the only way to save on fees is trying to sell your home by yourself, which is neither fast nor easy, and you are unlikely to get top dollar.</p>
          <p>But we’re here to tell you that’s not the only way.</p>
          <p>After 15 years as a top selling agency backed by the largest Remax in South Florida, we’ve perfected a formula of 5 things you can do to not only sell your house for top dollar, but do it very rapidly – while also saving an average of $15,000 dollars on fees and commission.</p>
        </div>
      </div>
    </div>

    <div className="benefits-box is-new is-animated" ref={benefitsElement}>
      <div className="site-content">
        <h2>Inside our Free Report “The 5 Hidden Secrets For Selling Your Home FAST, At Top Dollar, While Saving $15,000 Average” we’ll reveal:</h2>
        <div className="benefits-box-list">
          <div className="items-row">
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow1} alt="In-Depth Market Insight" />
              </figure>
              <div>One easy way to instantly increase the value of your home – that won’t cost you a dime.</div>
            </div>
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow2} alt="Masterful Negotiation Skills" />
              </figure>
              <div>How to make your home extremely popular online with just three simple tweaks.</div>
            </div>
          </div>
          <div className="items-row">
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow3} alt="Avoid Unnecessary Fees" />
              </figure>
              <div>The real marketing checklist for creating enough demand to sell your home over market value very quickly. (Miss one of these items and you instantly decrease the market value of your home.)</div>
            </div>
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow4} alt="We Handle EVERYTHING" />
              </figure>
              <div>What you should do to give your home a “wow” effect when showing.</div>
            </div>
          </div>
          <div className="items-row">
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow4} alt="Avoid Unnecessary Fees" />
              </figure>
              <div>How to draw more attention to your house over and above all other houses that are competing with yours for the sale.</div>
            </div>
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow4} alt="We Handle EVERYTHING" />
              </figure>
              <div>What you should know about the “behind the scenes” things your realtor is (or isn’t) doing. If you want top dollar AND to sell FAST, then your realtor must be doing all of these.</div>
            </div>
          </div>
          <div className="items-row">
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow3} alt="Avoid Unnecessary Fees" />
              </figure>
              <div>The ONE and ONLY way to save multiple THOUSANDS (on average $15,000 in our experience) when selling your home by slashing sellers fees and commission.</div>
            </div>
            <div className="benefits-box-item">
              <figure>
                <img src={iconHow4} alt="We Handle EVERYTHING" />
              </figure>
              <div>And much more…</div>
            </div>
          </div>
        </div>
        <div className="report-form">
          <CustomLink to="/contact" className="button">Send Me My Free Report Now</CustomLink>
        </div>
        {/*
        <div className="warning">
          <div className="warning-title">Warning:</div>
          <div className="warning-body">You won’t find this formula on any other website or offered by any other realtors. Plus, because of the value of the secrets revealed, this may only be offered for a short time.</div>
          <div className="last-note">Download your FREE report NOW while you still can!</div>
        </div>
        */}
      </div>
    </div>
    <div className="home-hero new-version">
      <div className="home-hero-inner">
        <div className="search-form">
          <div className="note">
            Discover Your Potential Savings with 1on1 Home: Simply Enter Your Address to Find Out How Much You Can Save.
          </div>
          <SearchBox />
        </div>
        <div className="contact-expert">
          <div className="details">
            <div className="details-box">
              Get Your Free Home Analysis & Start Today
            </div>
            <div className="details-box">
              30 Day Listing Agreements
            </div>
            <div className="details-box">
              Receive A Guaranteed Offer On Your Property TODAY!
            </div>
          </div>
          <CustomLink to="/contact" className="button bordered">Speak to an Expert</CustomLink>
        </div>
      </div> 
    </div>
    <SavingsSimulator />
  </>
}
