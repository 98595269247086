import Slider from 'react-slick'

export default function Reviews() {
	const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return <div className="reviews">
    <div className="site-content">
      <div className="testimonials-subtitle">We Didn’t Say It, They Did…</div>
      <div className="section-title">Testimonials</div>
      <div className="row">
        <Slider {...settings}>
          <div className="item">
            <div className="review-image">
            </div>
            <div className="review-content">
              <div className="first-line">
                <div className="review-details">
                  <span className="author-name">Jessica E.</span>
                </div>
                <div className="stars">
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                </div>
              </div>
              <div className="review-body">
                1on1Home was great! They sold my home fast and I got a great price for it.
                Plus, I saved over $15,000 on fees and commissions.
                Super happy with their service and would definitely recommend them!
              </div>
            </div>
          </div>

          <div className="item">
            <div className="review-image">
            </div>
            <div className="review-content">
              <div className="first-line">
                <div className="review-details">
                  <span className="author-name">John S.</span>
                </div>
                <div className="stars">
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                </div>
              </div>
              <div className="review-body">
                Working with the 1on1Home team was a fantastic experience!
                From the free consultation to the speedy handling of all the necessary forms, everything was handled professionally.
                I sold my property quickly and saved a lot of money in the process.
                I'm more than happy with my decision to go with 1on1Home. 
                Thanks to the entire team!
              </div>
            </div>
          </div>

          <div className="item">
            <div className="review-image">
            </div>
            <div className="review-content">
              <div className="first-line">
                <div className="review-details">
                  <span className="author-name">Sara N.</span>
                </div>
                <div className="stars">
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                </div>
              </div>
              <div className="review-body">
                Exceptional service and significant savings—1on1Home made selling my home fast, easy, and financially rewarding!
              </div>
            </div>
          </div>

          <div className="item">
            <div className="review-image">
            </div>
            <div className="review-content">
              <div className="first-line">
                <div className="review-details">
                  <span className="author-name">Dani K.</span>
                </div>
                <div className="stars">
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                </div>
              </div>
              <div className="review-body">
                I highly recommend this brokerage if you're looking to sell your home!
                They made the whole process effortless and efficient, ensuring I got the best price possible.
                Their team is dedicated and really knows how to handle everything from start to finish. 
                Thanks to their expert strategies and personalized service, I was able to sell quickly and save money.
                Definitely the right choice for a stress-free sale!
              </div>
            </div>
          </div>

          <div className="item">
            <div className="review-image">
            </div>
            <div className="review-content">
              <div className="first-line">
                <div className="review-details">
                  <span className="author-name">Josh O.</span>
                </div>
                <div className="stars">
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                  <span className="star"></span>
                </div>
              </div>
              <div className="review-body">
                Choosing 1on1Home was the best decision I made—quick sale, top dollar, and incredible savings on fees!
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  </div>
}
