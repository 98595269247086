import { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Properties from '../components/Properties'
import OffersListing from '../components/OffersListing'
import useUserPermissions from '../hooks/useUserPermissions'
import { formatPhoneNumber } from '../helpers'

export default function Dashboard() {
	const [user, setUser] = useState(null)
	const [offers, setOffers] = useState([])
	const [loading, setLoading] = useState(true)
	const { isAdmin } = useUserPermissions()
	const { id } = useParams()

	useEffect(() => {
		setLoading(true)

		axios.get(`/users/${id}`).then(({data}) => setUser(data.data))
		axios.get(`/offers?user_id=${id}`).then(({data}) => {
			setOffers(data.data)
			setLoading(false)
		})
	}, [id])

	if (!user) {
		return null
	}

	return <>
  	<h2 className="admin-page-title">{user.name} ({formatPhoneNumber(user.phone)}) - {user.email}</h2>
  	<div className="widgets">
  		<div>
  			<div className="admin-section-title">Active Property</div>
				{
					(user && user.properties ? user.properties : []).length
					? <Properties 
						realData={true}
	  				hideHeadBar={true} 
	  				className="is-animated"
	  				properties={user.properties}
	  				allowAdminControls={isAdmin}
					/>
					: <div>
						No property listed at the moment...
					</div>
				}
			</div>
  	</div>

  	<div className="admin-section-title">Offers</div>

  	<OffersListing 
			entries={offers}
			loading={loading}
		/>
  </>
}
