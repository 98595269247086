import CustomLink from '../components/CustomLink'

export default function Error404() {
  return <div className="auth-screen move-up">
    <div className="auth-screen-bkg1">
  		<div className="auth-screen-bkg2">
    		<h2>Error 404 - Page not found!</h2>
    		<div>
      		<CustomLink to="/" className="button is-white">Go to the home page</CustomLink>
    		</div>
  		</div>
		</div>
	</div>
}
