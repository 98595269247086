const initAnimationObserver = (element, callback=null) => {
  const { current } = element

  if (current) {
    if (!current.classList.contains('is-initialized')) {
      current.classList.add('is-initialized')

      const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          current.classList.add('is-animated')

          if (callback) {
            callback(current)
          }
        } else {
          //current.classList.remove('is-animated')
        }
      }, {
        threshold: 0.75
      })

      observer.observe(current)
    }
  }
}

function formatPhoneNumber(phoneNumber) {
  if (typeof phoneNumber === 'undefined') {
    return ''
  }

  phoneNumber = phoneNumber.toString().replace(/-/g, '')

  if (phoneNumber.length >= 7) {
    return phoneNumber.substring(0,3) + '-' + phoneNumber.substring(3,6) + '-' + phoneNumber.substring(6)
  }

  if (phoneNumber.length >= 4) {
    return phoneNumber.substring(0,3) + '-' + phoneNumber.substring(3)
  }

  return phoneNumber
}

function formatPrice(price) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export {
	initAnimationObserver,
  formatPhoneNumber,
  formatPrice,
  capitalizeFirstLetter
}
