import CtaBox from '../components/CtaBox'
import Properties from '../components/Properties'

// const Separator = () => <div className="filters-separator">
//   <svg xmlns="http://www.w3.org/2000/svg" width="292" height="2" viewBox="0 0 292 2" fill="none">
//     <path d="M0 1H292" stroke="black" strokeOpacity="0.07"/>
//   </svg>
// </div>

export default function Search() {
  return <div className="site-content">
    {/*<div className="left-sidebar">*/}
      {/*<aside className="search-filters">
        <h2 className="size-s">Filter</h2>

        {
          [
            {
              title: 'Model',
              options: ['All', 'For Sale', 'For Rent', 'Sold']
            },
            {
              title: 'Type',
              options: ['All', 'For Sale', 'For Rent', 'Sold']
            },
            {
              title: 'Number of Bedrooms',
              options: ['All', '1+', '2+', '3+', '4+', '5+']
            },
            {
              title: 'Number of Bathrooms',
              options: ['All', '1+', '2+', '3+', '4+', '5+']
            }
          ].map(filter => <div className="filter-group" key={filter.title}>
            <Separator />
            <h3>{filter.title}</h3>
            <ul>
              {
                filter.options.map(option => <li key={option}>
                  <label>
                    <input type="checkbox" />
                    {option}
                  </label>
                </li>)
              }
            </ul>
          </div>)
        }

        <div className="filter-group">
          <Separator />
          <h3>Price</h3>
          <ul>
            <li>
              <input type="number" placeholder="Minimum" />
            </li>
            <li>
              <input type="number" placeholder="Maximum" />
            </li>
          </ul>
        </div>

        <Separator />
      </aside>*/}

      <main>
        {/*<SearchBox />
        <div className="listing-top">
          10 results
        </div>
        <div className="listing">
          <div className="listing-articles">
            {
              [1,2,3,4,5,6,7,8,9,10].map(id => { return { id } }).map(property => <PropertyPlaceholder key={property.id} />)
            }
          </div>
          <button className="button gray full load-more">Load More</button>
        </div>
        */}

        <Properties 
          retsData={true}
          hideHeadBar={true}
        />
      </main>
    {/*</div>*/}
    <CtaBox />
  </div>
}
