import { useState } from 'react'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CustomLink from '../components/CustomLink'

export default function Register() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [name, setName] = useState(searchParams.get('name') || '')
  const [email, setEmail] = useState(searchParams.get('email') || '')
  const [phone] = useState(searchParams.get('phone') || '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [formErrors, setFormErrors] = useState([])

  return <form className="auth-box" onSubmit={event => {
    event.preventDefault()

    setSubmitted(true)
    setFormErrors([])

    axios.post('/users', {
      name,
      email,
      phone,
      password,
      type: '1on1user',
      confirm_password: confirmPassword,
      firstProperty: localStorage.getItem('1on1Home_googleData'),
      terms: true
    }).then(({ data }) => {
      setSubmitted(false)

      if (data.status === 'error') {
        const errors = data.errors
        const formErrors = []

        for (let i in errors) {
          formErrors.push(errors[i].join('; ').trim())
        }

        setFormErrors(formErrors)
      } else {
        localStorage.removeItem('1on1Home_googleData')
        localStorage.setItem('1on1Home_userId', data.data.id)
        navigate(`/dashboard`)
      }
    }).catch(function (error) {
      setSubmitted(false)
      alert(error)
    })
  }}>
    <h3 className="size-s">Sign In - It's FREE</h3>
    <div className="formfield">
      <input 
        type="text" 
        value={name}
        placeholder="Name" 
        onChange={({target}) => setName(target.value)}
      />
    </div>
    <div className="formfield">
      <input 
        type="email" 
        value={email}
        placeholder="Email" 
        onChange={({target}) => setEmail(target.value)}
      />
    </div>
    <div className="formfield">
      <input 
        type="password" 
        value={password}
        placeholder="Set Password" 
        onChange={({target}) => setPassword(target.value)}
      />
    </div>
    <div className="formfield">
      <input 
        type="password" 
        value={confirmPassword}
        placeholder="Confirm Password" 
        onChange={({target}) => setConfirmPassword(target.value)}
      />
    </div>

    {
      formErrors.map(formError => <div className="form-error" key={formError}>
        {formError}
      </div>)
    }

    <button className="button full" disabled={submitted}>Sign Up</button>
    {
      searchParams.get('email')
      ? null
      : <div className="auth-box-footer">
        Already have an account? <CustomLink to="/auth/login">Log In</CustomLink>
      </div>
    }
  </form>
}
